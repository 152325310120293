import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomizedInputBase } from '../../custom';
import { businesses, organizations, types, categories } from '../../../state';

const useStyles = makeStyles((theme) => ({
  fsBackground: {
    // background: `#ee6c4d`,
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      minHeight: '180px',
      maxHeight: '180px',
    },
  },
  styledGrid: {
    minHeight: '170px',
  },
}));

const FullscreenSection = ({
  searchByName,
  organization,
  filterByType,
  setSelectedCategoryFilter,
  selectedType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        className={classes.styledGrid}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
          xs={12}
          md={12}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography component="div" align="center" variant="h5">
              {t('pickACardandWeWillDonate')}{' '}
              {organization.name
                ? organization.name
                : t('yourSelectedNonprofit')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Formik
              initialValues={{ search: '' }}
              onSubmit={(values, actions) => {
                setSelectedCategoryFilter('');
                if (!values.search) {
                  actions.setSubmitting(false);
                  return filterByType(selectedType);
                }
                actions.setSubmitting(false);

                return searchByName(values);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Form>
                  <Field
                    component={CustomizedInputBase}
                    handleClick={handleSubmit}
                    isSubmitting={isSubmitting}
                    fullWidth
                    name="search"
                    aria="search"
                    placeholder={t('searchByMerchantName')}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

FullscreenSection.defaultProps = {
  organization: {},
};

FullscreenSection.propTypes = {
  searchByName: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
  }),
  filterByType: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  setSelectedCategoryFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  organization: organizations.selectors.selectOrganization(state),
  selectedType: types.selectors.selectSelectedType(state),
});

const mapDispatchToProps = (dispatch) => ({
  searchByName: (payload) => dispatch(businesses.actions.searchByName(payload)),
  filterByType: (payload) => dispatch(businesses.actions.filterByType(payload)),
  setSelectedCategoryFilter: (payload) =>
    dispatch(categories.actions.setSelectedCategoryFilter(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenSection);
