import React from 'react';
import { Typography, Grid, Box, Button, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tickets } from '../../../state';
import ChargeBreakdown from './ChargeBreakdown';
import TicketImgCard from './TicketImgCard';

// BREAK DOWN THIS COMPONENT

const TransactionDetails = () => {
  const { t } = useTranslation();
  // const savedAmount =
  //   Number(totalAmount) + Number(fees) - Number(customerPaysAmount);
  // const ticketConfig = useSelector(tickets.selectors.selectTicketEvent);
  const { orderNumber } = useSelector(tickets.selectors.selectFormData);
  const handleBuyMoreCards = () => {
    window.location.reload();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          <b>{t('transactionSuccessful')}</b>
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          {t('orderNumber')} <b>{orderNumber}</b>
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('ticket.receiveEmail')}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('ticket.ticketPurchasedFor')}:
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <Card raised>
              <TicketImgCard />
            </Card>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box width="100%" pt={1}>
              <ChargeBreakdown />
            </Box>
            <Box py={2} align="center">
              <Button
                onClick={handleBuyMoreCards}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                {t('ticket.buyMore')}
              </Button>
            </Box>
          </Grid>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Grid item xs={12} sm={3} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TransactionDetails;
