import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider } from '@material-ui/core';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, loyalties, organizations } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter, Content } from '../../layouts';
import LoyaltyRegisterForm from './LoyaltyRegisterForm';

const LoyaltyRegister = ({
  fetchBusiness,
  fetchOrganization,
  business,
  location,
  fetchOrganizations,
  ...nav
}) => {
  const { i18n } = useTranslation();
  const { id: routeId } = nav;
  const { org: orgId } = queryString.parse(location.search);
  const dispatch = useDispatch();
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const loyaltyConfig = useSelector(loyalties.selectors.selectProgram);
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: loyaltyConfig.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  React.useEffect(() => {
    const fn = async () => {
      const loyaltyProgram = await dispatch(
        loyalties.actions.getLoyaltyProgram({ id: routeId })
      );

      if (loyaltyProgram?.error || loyaltyProgram?.payload?.hidden) {
        return navigate('/');
      }
      dispatch(
        businesses.actions.fetchMerchant(loyaltyProgram.payload.businessId)
      );
      return null;
    };
    fn();
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { logoUrl } = business;
  const merchantPintunaUrl = !orgId
    ? `/card/${routeId}`
    : `/card/${routeId}?org=${orgId}`;

  const { t } = useTranslation();

  return (
    <Box className={classes.textColor}>
      <SEO title={t('loyalty.loyaltySignUp')} favicon={logoUrl} />
      {!isLoading && (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl=""
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={loyaltyConfig.brandColors?.headerColor || '#eee'}
        />
      )}
      <Box bgcolor={loyaltyConfig.brandColors?.backgroundColor || '#eee'}>
        <Container maxWidth="lg">
          <Content md={7} sm={10}>
            <LoyaltyRegisterForm routeId={routeId} />
          </Content>
        </Container>
      </Box>
      <Divider />
      <BusinessFooter bgcolor={loyaltyConfig.brandColors?.footerColor} />
    </Box>
  );
};

LoyaltyRegister.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    culture: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyRegister);
