import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  TextField as FormikTextField,
  Checkbox,
} from 'formik-material-ui';
import {
  TextField as MUITextField,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Button,
  useMediaQuery,
  FormHelperText,
} from '@material-ui/core';
import { Autocomplete } from 'formik-material-ui-lab';
import { Skeleton } from '@material-ui/lab';
import { getOr, isEmpty } from 'lodash/fp';
import { navigate as navigateGatsby } from 'gatsby';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReCaptcha from '../../ReCaptcha';

import {
  businesses,
  organizations,
  checkout,
  checkCardBalance,
} from '../../../state';
import { UserInfoSchema } from './validation';
import CustomInputField from '../../custom/CustomInputField';
import {
  handleSquareSubmit,
  handleStripeSubmit,
  handleCloverSubmit,
} from './PosSubmits';

const useStyles = makeStyles((theme) => ({
  btn: {
    borderColor: theme.palette.light,
    flexGrow: 1,
  },
  skeleton: {
    marginRight: '5px',
    height: '70px',
    width: '20%',
  },
}));

// TODO: return alert snackbar
const UserInfoForm = ({
  business,
  organization,
  getSecret,
  allOrganizations,
  setFormData,
  isLoading,
  getTotals,
  cardDetails,
}) => {
  const recaptcha = React.createRef();
  const classes = useStyles();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  const [customAmount, setCustomAmount] = React.useState(false);

  const cardValues = getOr([], 'amounts', business);
  const minCardAmount = cardValues[0];
  const {
    id,
    allowCustomAmount,
    minAmount,
    maxAmount,
    partner,
    country,
    giveBack,
    discounts,
    customAmountDiscount,
    currency,
  } = business;
  const isBhnPartner = getOr('', 'partner', business).includes('BHN');
  const hasOrgId = organization.id;
  const handleBack = () => {
    return !isEmpty(organization)
      ? navigateGatsby(`/?org=${organization.id}`)
      : navigateGatsby(`/`);
  };

  if (isLoading && !cardDetails.cardNumber) {
    return <CircularProgress size={24} />;
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={() =>
        UserInfoSchema(maxAmount, minAmount, cardDetails.balance, currency)
      }
      initialValues={{
        businessId: cardDetails.businessId || '',
        giftCardConfigId: cardDetails.giftCardConfigId || '',
        giftCardImageId: cardDetails.giftCardImageId || '',
        giftCardTermId: cardDetails.giftCardTermId || '',
        languageId: cardDetails.languageId || '',
        occasionId: cardDetails.occasionId || '',
        organizationId: organization.id || undefined,
        organizationName: organization.name || undefined,
        orgCode: organization.code || null,
        email: '',
        firstName: '',
        lastName: '',
        promoCode: '',
        sameAsRecepient: false,
        amount: minCardAmount,
        currency: cardDetails.currency || 'USD',
        country: country || 'US',
        code: cardDetails.cardNumber || '',
        balance: cardDetails.balance || '',
        giftFirstName: cardDetails.giftFirstName || cardDetails.firstName || '',
        giftLastName: cardDetails.giftLastName || cardDetails.lastName || '',
        giftEmail: cardDetails.giftEmail || cardDetails.email || '',
        giftMessage: '',
        customAmount: false,
        fees: cardDetails.fees,
        feesHelperText: cardDetails.feesHelperText,
        locationId: business.locationId || cardDetails?.locationId,
      }}
      onSubmit={async (values, actions) => {
        const isHuman = await recaptcha.current.props.grecaptcha.getResponse();
        if (isHuman) {
          actions.setSubmitting(true);
          // eslint-disable-next-line no-param-reassign
          values.isGift = values.giftEmail?.trim() !== values.email?.trim();
          if (partner === 'Square') {
            await handleSquareSubmit(values, getTotals, setFormData);
          }
          if (partner === 'BHN') {
            await handleSquareSubmit(values, getTotals, setFormData);
          }
          if (partner === 'Stripe') {
            await handleStripeSubmit(values, getSecret, setFormData);
          }
          if (partner === 'Clover') {
            await handleCloverSubmit(values, getTotals, setFormData);
          }
          recaptcha.current.props.grecaptcha.reset();
          if (!isEmpty(organization)) {
            navigateGatsby(`/add-funds/${id}/checkout?org=${organization.id}`);
          } else {
            navigateGatsby(`/add-funds/${id}/checkout`);
          }
          return;
        }
        recaptcha.current.execute();
        actions.setSubmitting(false);
      }}
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        handleSubmit,
        errors,
        validateField,
      }) => {
        const { amount, sameAsRecepient, organizationName } = values;
        const onResolved = () => {
          handleSubmit();
        };
        const handleCardValueClick = (cardValue) => {
          setFieldValue('amount', cardValue);
          setFieldValue('customAmount', false);
          setCustomAmount(false);
          setTimeout(() => validateField('amount'));
        };

        const handleCustomAmountChange = (e) => {
          setFieldValue('amount', e.target.value);
        };

        useEffect(() => {
          setFieldValue(
            'firstName',
            sameAsRecepient ? values.giftFirstName : ''
          );
          setFieldValue('lastName', sameAsRecepient ? values.giftLastName : '');
          setFieldValue('email', sameAsRecepient ? values.giftEmail : '');
        }, [sameAsRecepient]);

        return (
          <Form>
            <Box
              pt={4}
              pb={2}
              sx={{
                padding: '10px 15px',
                border: `1px solid ${theme.palette.text.secondary}`,
                borderRadius: '5px',
                opacity: 0.5,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={7}>
                  <CustomInputField
                    customInput={Field}
                    component={FormikTextField}
                    label={t('giftCardNumber')}
                    name="code"
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <CustomInputField
                    customInput={Field}
                    component={FormikTextField}
                    label={t('cardBalance')}
                    name="balance"
                    size="small"
                    value=""
                    startAdornment={t('cardValue', {
                      amount: values.balance,
                      formatParams: {
                        amount: { currency },
                      },
                    })}
                    variant="outlined"
                    fullWidth
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Box pt={2} pb={2}>
                <Typography variant="body2">
                  {t('reloadedGiftCardWillBeSentToOriginalRecipient')}
                </Typography>
              </Box>
              <Box pb={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      required
                      label={t('autoRecipientFirstName')}
                      name="giftFirstName"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      capitalize
                      fullWidth
                      required
                      label={t('autoRecipientLastName')}
                      name="giftLastName"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pb={2}>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  trim
                  fullWidth
                  required
                  label={t('autoRecipientEmail')}
                  name="giftEmail"
                  variant="outlined"
                  disabled
                />
              </Box>
            </Box>
            <Box pt={2} pb={1}>
              <Typography variant="body2">{t('giftCardAmount')}</Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="nowrap"
              pb={2}
              width="100%"
              justifyContent="center"
            >
              {isLoading ? (
                <>
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton height="70px" width="20%" />
                </>
              ) : (
                <ToggleButtonGroup
                  id="amount"
                  aria-label="Select card value"
                  style={{ width: '100%' }}
                >
                  {cardValues.map(
                    (cardValue, i) =>
                      cardValue && (
                        <ToggleButton
                          variant="contained"
                          color="primary"
                          selected={cardValue === amount}
                          onClick={() => handleCardValueClick(cardValue)}
                          key={cardValue}
                          value={cardValue}
                          aria-label={cardValue}
                          className={classes.btn}
                        >
                          <Box display="flex" flexDirection="column">
                            {!isEmpty(discounts) && discounts[i] < cardValue ? (
                              <>
                                <Box>
                                  {t('cardValue', {
                                    amount: discounts[i].toFixed(2),
                                    formatParams: {
                                      amount: { currency: business.currency },
                                    },
                                  })}
                                </Box>
                                <Typography
                                  variant="caption"
                                  style={{
                                    textTransform: 'none',
                                    lineHeight: '1.25rem',
                                    display: 'inline-block',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                  }}
                                >
                                  {t('actualValue')}
                                  <br />
                                  {t('cardValue', {
                                    amount: cardValue.toFixed(2),
                                    formatParams: {
                                      amount: { currency: business.currency },
                                    },
                                  })}
                                  <br />
                                  {`(${(
                                    ((cardValue - discounts[i]) / cardValue) *
                                    100
                                  ).toFixed(1)}% ${t('off')})`}
                                </Typography>
                              </>
                            ) : (
                              t('cardValue', {
                                amount: cardValue.toFixed(2),
                                formatParams: {
                                  amount: { currency: business.currency },
                                },
                              })
                            )}
                          </Box>
                        </ToggleButton>
                      )
                  )}
                  {allowCustomAmount && (
                    <ToggleButton
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      size="small"
                      selected={customAmount === true}
                      onClick={() => {
                        setFieldValue('amount', '');
                        setFieldValue('customAmount', true);
                        setCustomAmount(true);
                        validateField('amount');
                      }}
                      key="custom"
                      value="custom"
                      aria-label="custom"
                    >
                      <Box display="flex" flexDirection="column">
                        <Box>{t('custom')}</Box>
                        {!!customAmountDiscount && (
                          <Typography variant="caption">
                            {`(${(customAmountDiscount * 100).toFixed(
                              1
                            )}% off)`}
                          </Typography>
                        )}
                      </Box>
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              )}
            </Box>
            {allowCustomAmount && customAmount && (
              <Box pb={2}>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  fullWidth
                  name="amount"
                  label={t('enterCustomAmount')}
                  variant="outlined"
                  trimLeadingZeros
                  onChange={handleCustomAmountChange}
                  startAdornment={cardDetails.currency}
                />
              </Box>
            )}
            {!!giveBack && (
              <>
                <Box pb={2}>
                  {!hasOrgId ? (
                    <Field
                      component={Autocomplete}
                      name="organizationId"
                      value={undefined}
                      autoHighlight
                      options={allOrganizations}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      clearOnBlur
                      clearOnEscape
                      selectOnFocus
                      freeSolo
                      disabled={hasOrgId || isSubmitting}
                      onChange={(e, org) => {
                        setFieldValue('organizationId', org ? org.id : '');
                        setFieldValue('organizationName', org ? org.name : '');
                        setFieldValue('orgCode', org ? org.code : '');
                      }}
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          // error={
                          //   !values.organizationId && !!errors.organizationId
                          // }
                          // helperText={
                          //   !values.organizationId && errors.organizationId
                          // }
                          label={t('supportedNonprofitStartTyping')}
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      fullWidth
                      disabled
                      name="organizationName"
                      label={t('supportedNonprofit')}
                      variant="outlined"
                      InputProps={{ value: organizationName || '' }}
                    />
                  )}
                </Box>
                <Box pb={2} display="flex" alignItems="center">
                  {!isBhnPartner && (
                    <Typography variant="caption">
                      {t('noteThreeCreditCardProcessingFee')}
                    </Typography>
                  )}
                </Box>
              </>
            )}
            {errors.amount && !customAmount ? (
              <FormHelperText error>{errors.amount}</FormHelperText>
            ) : null}
            <h3>{t('YourInformation')}</h3>
            <Box pb={2} display="flex" alignItems="center">
              <Box mr="3">
                <Field
                  type="checkbox"
                  component={Checkbox}
                  color="primary"
                  name="sameAsRecepient"
                />
              </Box>
              <Typography variant="caption">{t('sameAsRecepient')}</Typography>
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label={t('yourFirstName')}
                    name="firstName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label={t('yourLastName')}
                    name="lastName"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                label={t('yourEmail')}
                name="email"
                variant="outlined"
              />
            </Box>
            <Box pb={mobile ? 2 : 10}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    size="large"
                  >
                    {t('back')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('continue')
                    )}
                  </Button>
                </Grid>
              </Grid>
              <ReCaptcha onResolved={onResolved} createRef={recaptcha} />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

UserInfoForm.defaultProps = {
  allOrganizations: [],
};

UserInfoForm.propTypes = {
  getSecret: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amounts: PropTypes.arrayOf(PropTypes.number),
    maxAmount: PropTypes.number,
    minAmount: PropTypes.number,
    allowCustomAmount: PropTypes.bool,
    imageUrl: PropTypes.string,
    partner: PropTypes.string,
    minFeeCoverAmount: PropTypes.number,
    country: PropTypes.string,
    currency: PropTypes.string,
    giveBack: PropTypes.number,
    customAmountDiscount: PropTypes.number,
    discounts: PropTypes.arrayOf(PropTypes.number),
    locationId: PropTypes.string,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTotals: PropTypes.func.isRequired,
  allOrganizations: PropTypes.arrayOf(PropTypes.object),
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    giftCardConfigId: PropTypes.string,
    giftCardImageId: PropTypes.string,
    giftCardTermId: PropTypes.string,
    occasionId: PropTypes.string,
    languageId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    giftFirstName: PropTypes.string,
    giftLastName: PropTypes.string,
    giftEmail: PropTypes.string,
    fees: PropTypes.number,
    feesHelperText: PropTypes.string,
    locationId: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  organization: organizations.selectors.selectOrganization(state),
  allOrganizations: organizations.selectors.selectOrganizations(state),
  isLoading: businesses.selectors.selectIsLoading(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSecret: (payload) => dispatch(checkout.actions.getSecret(payload)),
  setFormData: (payload) => dispatch(checkout.actions.setFormData(payload)),
  getTotals: (payload) => dispatch(checkout.actions.getTotals(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoForm);
