import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import defaultCardImage from '../../../images/defaultGiftCard.jpg';
import { CustomImgCard } from '../../custom';
import { businesses, checkout, checkCardBalance } from '../../../state';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

const OrderDetails = ({ business, cardDetails }) => {
  const { t } = useTranslation();
  const formData = useSelector(checkout.selectors.selectFormData);
  if (!business && formData && !cardDetails) {
    return <CircularProgress />;
  }

  const {
    cardAmount,
    firstName,
    lastName,
    email,
    isGift,
    giftEmail,
    giftFirstName,
    giftLastName,
    deliverAt,
  } = formData;
  const { name, imageUrl, currency } = business;

  const [imgUrl, setImgUrl] = useState(imageUrl);

  useEffect(() => {
    if (cardDetails.imageUrl) {
      setImgUrl(cardDetails.imageUrl);
    }
  }, [cardDetails]);
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card raised>
          <Box display="block" align="middle">
            <CustomImgCard
              name={name || 'default image'}
              imageUrl={imgUrl || `${defaultCardImage}`}
              elevation={0}
              square
              width="272px"
            />
          </Box>
          <CardContent>
            {cardDetails.cardNumber && (
              <Grid
                container
                display="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="Charge breakdown">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {t('giftCard')}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {cardDetails.cardNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {t('currentBalance')}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {t('cardValue', {
                              amount: cardDetails.balance,
                              formatParams: {
                                amount: { currency },
                              },
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              component="p"
                            >
                              {t('newBalance')}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              display="block"
                              align="right"
                              variant="body2"
                            >
                              <b>
                                {t('cardValue', {
                                  amount:
                                    cardDetails.balance + Number(cardAmount),
                                  formatParams: {
                                    amount: { currency: business.currency },
                                  },
                                })}
                              </b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              display="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="body2">
                  <b>
                    {isGift
                      ? `${giftFirstName} ${giftLastName}`
                      : `${firstName} ${lastName}`}
                  </b>
                </Typography>
              </Grid>
              {!cardDetails.cardNumber && (
                <Grid item xs={6}>
                  <Typography display="block" align="right" variant="body2">
                    <b>
                      {t('cardValue', {
                        amount: cardAmount,
                        formatParams: {
                          amount: { currency: business.currency },
                        },
                      })}
                    </b>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${isGift ? giftEmail : email}`}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${
                deliverAt
                  ? `${t('delivery')}: ${moment(deliverAt).format(
                      'MMMM DD, YYYY'
                    )}`
                  : ''
              }`}
            </Typography>
            <Box pt={1}>
              <Typography variant="caption" color="textSecondary" component="p">
                {`${t('purchaselNote')} ${email}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

OrderDetails.defaultProps = {
  business: {},
};

OrderDetails.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    giveBack: PropTypes.number,
    imageUrl: PropTypes.string,
    currency: PropTypes.string,
    fees: PropTypes.number,
  }),

  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    imageUrl: PropTypes.string,
    // currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  formData: checkout.selectors.selectFormData(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

export default connect(mapStateToProps)(OrderDetails);
