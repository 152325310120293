import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const sitekey = `${process.env.GATSBY_CAPTCHA_SITE_KEY}`;

const ReCaptcha = ({ onResolved, createRef }) => {
  const onErrored = () => {
    window?.recaptcha?.current?.props?.reset();
  };

  const onExpired = () => {
    window?.recaptcha?.current?.props?.reset();
  };

  return (
    <ReCAPTCHA
      // eslint-disable-next-line no-return-assign
      ref={createRef}
      size="invisible"
      sitekey={sitekey}
      onChange={onResolved}
      onErrored={onErrored}
      onExpired={onExpired}
      badge="bottomright"
      render="explicit"
    />
  );
};

ReCaptcha.propTypes = {
  onResolved: PropTypes.func.isRequired,
  createRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ReCaptcha;
