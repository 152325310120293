import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  CardActionArea,
  CardMedia,
  Card,
  makeStyles,
} from '@material-ui/core';

import facebookShare from '../../../images/facebookShare.ico';
import twitterShare from '../../../images/twitterShare.ico';

const useStyles = makeStyles({
  cardContainer: { maxWidth: '130px', maxHeight: '30px' },
  mediaContainer: { width: '130px', height: '30px' },
});

const SocialShareSection = ({ organizationName, orgCode }) => {
  const classes = useStyles();
  const facebookLink = `https://www.facebook.com/sharer.php?u=https://app.pintuna.com/?org=${orgCode}&src=facebook`;
  const twitterLink = `https://twitter.com/intent/tweet?text=I+just+bought+a+gift+card+on+Pintuna+to+support+${organizationName}+https://app.pintuna.com/?org=${orgCode}&src=twitter`;

  return (
    <>
      {organizationName && (
        <Box pt={2}>
          <Typography gutterBottom variant="body2" component="p">
            <b>
              Spread the word and tell your friends and family that you support{' '}
              {`${organizationName}`}
            </b>
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="center" pt={2}>
        <Card className={classes.cardContainer}>
          <CardActionArea component="a" href={facebookLink} target="_blank">
            <CardMedia
              component="img"
              image={facebookShare}
              title="Share on Facebok"
              className={classes.mediaContainer}
            />
          </CardActionArea>
        </Card>
        <Box pl={2}>
          <Card className={classes.cardContainer}>
            <CardActionArea component="a" href={twitterLink} target="_blank">
              <CardMedia
                component="img"
                image={twitterShare}
                title="Share on Twitter"
                className={classes.mediaContainer}
              />
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </>
  );
};
SocialShareSection.propTypes = {
  organizationName: PropTypes.string.isRequired,
  orgCode: PropTypes.number.isRequired,
};

export default SocialShareSection;
