import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
} from '@material-ui/core';
import i18n from 'i18next';
import { handleIOSMsg } from '../../../utils';
import { checkout, alert, businesses, tickets } from '../../../state';
import './styles.css';
import PoweredBySquare from '../../../images/poweredBySquare.svg';

const appId = process.env.GATSBY_APLICATION_ID;
const sandbox = process.env.GATSBY_SANDBOX_SQUARE_PAYMENT_FORM;
// eslint-disable-next-line react/prop-types
const CheckoutSquare = ({ changeStep }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector(tickets.selectors.selectFormData);
  const setFormData = (payload) =>
    dispatch(tickets.actions.setFormData(payload));
  const ticketCheckout = (payload) =>
    dispatch(checkout.actions.ticketCheckout(payload));
  const business = useSelector(businesses.selectors.selectBusiness);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [locationId, setLocationId] = useState(process.env.GATSBY_LOCATION_ID);
  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    if (business.locationId) {
      setLocationId(business.locationId);
    }
  }, [business]);

  const fieldStyles = [
    {
      color: `${theme.palette.primary.main}`,
      fontSize: `${theme.typography.fontSize}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      letterSpacing: '-0.8px',
      lineHeight: '20px',
      padding: '16px',
      inputContainer: { backgroundColor: 'red' },
    },
  ];

  const cardNonceResponseReceived = async (details, buyer) => {
    setIsSubmitting(true);
    if (details.status && details.status !== 'OK') {
      return;
    }

    const response = await ticketCheckout({
      ...formData,
      nonce: details?.token,
      verificationToken: buyer?.token,
      timezone: new Date().getTimezoneOffset(),
      culture: i18n.language,
    });

    if (response.error) {
      handleIOSMsg('payment_failed');
      openAlert({
        message:
          response.payload?.message ||
          t('weCannotProcessPaymentsPleaseTryLater'),
        severity: 'error',
      });
    } else if (response.payload) {
      setFormData({ orderNumber: response.payload?.orderNumber });
      changeStep(3);
    }
    setIsSubmitting(false);
  };

  const createVerificationDetails = () => ({
    amount: formData.totalAmount.toFixed(2).toString(),
    currencyCode: business.currency || 'USD',
    intent: 'CHARGE',
    billingContact: {
      familyName: formData.lastName,
      givenName: formData.firstName,
      email: formData.email,
      // country: 'US',
      // city: formData.city,
      // addressLines: [`${formData.line1}`],
      // postalCode: formData.postal_code,
    },
  });

  return formData?.totalAmount ? (
    <>
      <PaymentForm
        sandbox={sandbox === 'true'}
        applicationId={appId}
        locationId={locationId}
        cardTokenizeResponseReceived={cardNonceResponseReceived}
        createVerificationDetails={createVerificationDetails}
        inputStyles={fieldStyles}
      >
        <CreditCard>
          <>
            Pay (Total ={' '}
            {t('cardValue', {
              amount: formData.totalAmount,
              formatParams: {
                amount: {
                  currency: business.currency,
                },
              },
            })}
            )
          </>
        </CreditCard>
      </PaymentForm>
      <Box py={2} align="center">
        <img
          src={PoweredBySquare}
          alt="Powered By Square"
          style={{ height: 20 }}
        />
      </Box>
    </>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          onClick={() => cardNonceResponseReceived({}, {})}
          color="primary"
          variant="contained"
          textAlign="center"
          size="large"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            t('ticket.getTicket')
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CheckoutSquare;
