const countries = [
  { name: 'United States', number: '+1', code: 'US' },
  { name: 'Canada', number: '+1', code: 'CA' },
  { name: 'Australia', number: '+61', code: 'AU' },
  { name: 'France', number: '+33', code: 'FR' },
  { name: 'Germany', number: '+49', code: 'DE' },
  { name: 'Ireland', number: '+353', code: 'IE' },
  { name: 'Japan', number: '+81', code: 'JP' },
  { name: 'Netherlands', number: '+31', code: 'NL' },
  { name: 'New Zealand', number: '+64', code: 'NZ' },
  { name: 'Spain', number: '+34', code: 'ES' },
  { name: 'United Kingdom', number: '+44', code: 'GB' },
];

// eslint-disable-next-line import/prefer-default-export
export { countries };
