import * as Yup from 'yup';
import i18n from 'i18next';

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

const CheckoutSchema = () =>
  Yup.object().shape({
    // ccnumber: Yup.string().required('Required'),
    // cvc: Yup.string().required('Required'),
    // ccexp: Yup.string().required('Required'),
    line1: Yup.string().min(2, i18n.t('tooShort')).required(i18n.t('required')),
    city: Yup.string().min(2, i18n.t('tooShort')).required(i18n.t('required')),
    state: Yup.string().min(2, i18n.t('tooShort')).required(i18n.t('required')),
    postal_code: Yup.string()
      .typeError(i18n.t('numbersOnly'))
      .matches(zipRegExp, i18n.t('incorrectZipcode'))
      .test(
        'len',
        i18n.t('mustBeExactly5characters'),
        (val) => !val || (val && val.toString().length === 5)
      )
      .required(i18n.t('required')),
  });

export default CheckoutSchema;
