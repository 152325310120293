/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash/fp';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
} from '@material-ui/core';

import { CustomImgCard } from '../../custom';
import { businesses, types, categories } from '../../../state';

// const useStyles = makeStyles((theme) => ({
//   loadMore: {
//     border: `0.5px solid ${theme.palette.divider}`,
//     borderRadius: 15,
//     [theme.breakpoints.down('sm')]: {
//       height: '60px',
//     },
//     [theme.breakpoints.up('sm')]: {
//       minHeight: '179.1px',
//       height: 'calc(100% - 24px)',
//     },
//   },
// }));

const GiftCards = ({
  businessesList,
  fetchMore,
  lastBusinessId,
  isLoading,
  selectedType,
  selectedCategory,
  handleCardClick,
  handleTypeClick,
  isAll,
  isLoadingMore,
}) => {
  const debouncedFetch = debounce(300, () => {
    fetchMore({ lastBusinessId, selectedType, selectedCategory });
  });
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={4}>
        {businessesList.map(({ id, name, imageUrl, giveBack }) => (
          <Grid item key={id || 'comingsoon'} xs={12} sm={6} md={4}>
            {id ? (
              <CustomImgCard
                clickable
                isLoading={isLoading}
                name={name}
                imageUrl={imageUrl}
                giveBack={giveBack}
                details
                onClick={() => handleCardClick(id)}
              />
            ) : (
              <CustomImgCard
                clickable
                isLoading={isLoading}
                name={name}
                imageUrl={imageUrl}
                giveBack={giveBack || ''}
                details
                onClick={() => handleCardClick(id)}
              />
            )}
          </Grid>
        ))}
        {!businessesList.length && (
          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              display="block"
              align="center"
              style={{ fontSize: '80px' }}
            >
              &#129327;
            </Typography>
            <Typography display="block" align="center" variant="body1">
              {t('couldNotFindMerchants')}
            </Typography>
            <Box pt={2}>
              <Button
                component="button"
                variant="contained"
                color="primary"
                onClick={() => handleTypeClick(selectedType)}
              >
                {t('clear')}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      {isAll && (
        <Box pt={4} pb={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => debouncedFetch()}
              >
                {isLoadingMore ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  t('loadMore')
                )}
              </Button>
              {/* <CardActionArea
              className={classes.loadMore}
              onClick={() => debouncedFetch()}
              disabled={isLoadingMore}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                {isLoadingMore ? (
                  <CircularProgress />
                ) : (
                  <Typography display="block" align="center" variant="h6">
                    Load more
                  </Typography>
                )}
              </Box>
            </CardActionArea> */}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

GiftCards.defaultProps = {
  lastBusinessId: '',
  selectedType: '',
  selectedCategory: '',
};

GiftCards.propTypes = {
  businessesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastBusinessId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  handleCardClick: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  selectedCategory: PropTypes.string,
  handleTypeClick: PropTypes.func.isRequired,
  isAll: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  businessesList: businesses.selectors.selectAllBusinesses(state),
  lastBusinessId: businesses.selectors.selectLastBusinessId(state),
  isLoading: businesses.selectors.selectIsLoading(state),
  selectedType: types.selectors.selectSelectedType(state),
  selectedCategory: categories.selectors.selectSelectedCategory(state),
  isAll: businesses.selectors.selectIsAll(state),
  isLoadingMore: businesses.selectors.selectisLoadingMore(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMore: (payload) => dispatch(businesses.actions.fetchMore(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
