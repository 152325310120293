import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from 'react-square-web-payments-sdk';

import { connect } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash/fp';
import { navigate } from '@reach/router';

import i18n from 'i18next';
import { handleIOSMsg } from '../../../utils';

import { checkout, organizations, alert } from '../../../state';
import './styles.css';

const appId = process.env.GATSBY_APLICATION_ID;
// const locationId = process.env.GATSBY_LOCATION_ID;
const sandbox = process.env.GATSBY_SANDBOX_SQUARE_PAYMENT_FORM;
const CheckoutSquare = ({
  formData,
  setFormData,
  squareCheckout,
  organization,
  openAlert,
  isAddFunds,
  routeId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [locationId, setLocationId] = useState(process.env.GATSBY_LOCATION_ID);

  React.useEffect(() => {
    if (formData?.culture) {
      i18n.changeLanguage(formData.culture);
    }

    if (formData.locationId) {
      setLocationId(formData.locationId);
    }
  }, [formData]);

  const fieldStyles = [
    {
      color: `${theme.palette.primary.main}`,
      fontSize: `${theme.typography.fontSize}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      letterSpacing: '-0.8px',
      lineHeight: '20px',
      padding: '16px',
      inputContainer: { backgroundColor: 'red' },
    },
  ];
  const [errorMsgs, setErrorMsgs] = React.useState({});
  const [, setIsSubmitting] = React.useState(false);

  const cardNonceResponseReceived = async (details, buyer) => {
    console.log('details', details);
    console.log('buyer', buyer);
    if (details.status !== 'OK') {
      console.log(details);
      setErrorMsgs(
        'Card does not exist or something went wrong. Please try again later.'
      );
      setIsSubmitting(false);
      return;
    }

    setErrorMsgs([]);
    console.log(errorMsgs);

    const response = await squareCheckout({
      businessId: formData.businessId,
      giftCardConfigId: formData.id ?? formData.giftCardConfigId,
      organizationId: formData.organizationId,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      promoCode: formData.promoCode,
      giftEmail: formData.giftEmail,
      giftFirstName: formData.giftFirstName,
      giftLastName: formData.giftLastName,
      giftMessage: formData.giftMessage,
      isGift: formData.isGift,
      amount: formData.amount,
      giftAmount: formData.giftAmount,
      orgCode: formData.orgCode,
      nonce: details.token,
      verificationToken: buyer.token,
      code: formData.code,
      culture: i18n.language,
      giftCardImageId: formData.giftCardImageId,
      occasionId: formData.occasionId,
      languageId: formData.languageId,
      giftCardTermId: formData.giftCardTermId,
      deliverAt: formData.deliverAt,
    });

    const confirmationPath = isAddFunds
      ? `/add-funds/${routeId}/confirmation`
      : `/card/${routeId}/confirmation`;

    if (response.error) {
      handleIOSMsg('payment_failed');
      openAlert({
        message:
          response.payload?.message ||
          t('weCannotProcessPaymentsPleaseTryLater'),
        severity: 'error',
      });
    } else if (response.payload) {
      if (!isEmpty(organization)) {
        navigate(`${confirmationPath}?org=${organization.id}`, {
          replace: true,
        });
        return;
      }
      navigate(`${confirmationPath}`, { replace: true });
    }
    setIsSubmitting(false);
  };

  const createVerificationDetails = () => ({
    amount: formData.totalAmount,
    currencyCode: formData.currency || 'USD',
    intent: 'CHARGE',
    billingContact: {
      familyName: formData.lastName,
      givenName: formData.firstName,
      email: formData.email,
      // country: 'US',
      // city: formData.city,
      // addressLines: [`${formData.line1}`],
      // postalCode: formData.postal_code,
    },
  });

  const createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: formData.currency || 'USD',
      countryCode: formData.country || 'US',
      total: {
        label: 'Pintuna inc.',
        amount: formData.customerPaysAmount || formData.totalAmount,
        pending: false,
      },
      lineItems: [
        {
          label: 'Total',
          amount: formData.customerPaysAmount || formData.totalAmount,
          pending: false,
        },
      ],
    };
  };

  return (
    <>
      <PaymentForm
        sandbox={sandbox === 'true'}
        applicationId={appId}
        locationId={locationId}
        cardTokenizeResponseReceived={cardNonceResponseReceived}
        createVerificationDetails={createVerificationDetails}
        inputStyles={fieldStyles}
        createPaymentRequest={createPaymentRequest}
      >
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            setFormData(values);
            actions.setSubmitting(false);
          }}
        >
          {() => {
            // const submitForm = () => {
            //   const el = document.getElementById('rswp-card-button');
            //   el?.click();
            //   setIsSubmitting(true);
            //   handleSubmit();
            //   setIsSubmitting(false);
            // };

            // const handleSelect = (e) => {
            //   const state = e.target.value;
            //   setFieldValue('state', state);
            // };

            return (
              <Form>
                <CreditCard />
                <Box py={2} align="center">
                  <GooglePay />
                </Box>
                <Box align="center">
                  <ApplePay />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </PaymentForm>
    </>
  );
};

CheckoutSquare.propTypes = {
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    promoCode: PropTypes.string,
    giftEmail: PropTypes.string,
    giftFirstName: PropTypes.string,
    giftLastName: PropTypes.string,
    giftMessage: PropTypes.string,
    giftAmount: PropTypes.number,
    isGift: PropTypes.bool,
    organizationId: PropTypes.string,
    businessId: PropTypes.string,
    id: PropTypes.string,
    giftCardConfigId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    city: PropTypes.string,
    line1: PropTypes.string,
    postal_code: PropTypes.string,
    orgCode: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.string,
    country: PropTypes.string,
    code: PropTypes.string, // this is required for "add funds"
    culture: PropTypes.string,
    giftCardImageId: PropTypes.string,
    occasionId: PropTypes.string,
    languageId: PropTypes.string,
    giftCardTermId: PropTypes.string,
    deliverAt: PropTypes.string,
    locationId: PropTypes.string,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  squareCheckout: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  isAddFunds: PropTypes.bool.isRequired,
  routeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFormData: (payload) => dispatch(checkout.actions.setFormData(payload)),
  squareCheckout: (payload) =>
    dispatch(checkout.actions.squareCheckout(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSquare);
