import { getOr, omit } from 'lodash/fp';

const handleSquareSubmit = async (values, getTotals, setFormData) => {
  const { amount, id, giftName, giftCardConfigId } = values;
  const result = await getTotals({
    amount,
    id,
    giftCardConfigId,
  });

  const { totalAmount, giveBackAmount, customerPaysAmount, fees } = getOr(
    0,
    'payload',
    result
  );

  setFormData({
    ...values,
    amount: Number(amount),
    giftAmount: Number(amount),
    cardAmount: Number(amount).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
    transactionFee: Number(totalAmount - amount).toFixed(2),
    giveBackAmount: Number(giveBackAmount).toFixed(2),
    customerPaysAmount: Number(customerPaysAmount).toFixed(2),
    fees: Number(fees).toFixed(2),
    giftName:
      giftName ||
      (values.giftFirstName
        ? `${values.giftFirstName} ${values.giftLastName}`
        : ''),
  });
};

const handleStripeSubmit = async (values, getSecret, setFormData) => {
  const { amount, organizationName } = values;
  const payload = {
    ...omit(['organizationName'], values),
    amount: Number(amount),
    giftAmount: Number(amount),
  };

  const result = await getSecret(payload);
  const hasSecret = getOr('', ['payload', 'secret'], result);
  const { totalAmount, giveBackAmount, customerPaysAmount, fees } = getOr(
    '',
    'payload',
    result
  );
  if (hasSecret) {
    setFormData({
      ...payload,
      organizationName,
      cardAmount: Number(amount).toFixed(2),
      totalAmount: Number(totalAmount).toFixed(2),
      transactionFee: Number(totalAmount - amount).toFixed(2),
      giveBackAmount: Number(giveBackAmount).toFixed(2),
      customerPaysAmount: Number(customerPaysAmount).toFixed(2),
      fees: Number(fees).toFixed(2),
    });
  }
};

const handleCloverSubmit = async (values, getTotals, setFormData) => {
  const { amount, id, giftName, giftCardConfigId } = values;
  const result = await getTotals({
    amount,
    id,
    giftCardConfigId,
  });

  const { totalAmount, giveBackAmount, customerPaysAmount, fees } = getOr(
    0,
    'payload',
    result
  );
  setFormData({
    ...values,
    amount: Number(amount),
    giftAmount: Number(amount),
    cardAmount: Number(amount).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
    transactionFee: Number(totalAmount - amount).toFixed(2),
    giveBackAmount: Number(giveBackAmount).toFixed(2),
    customerPaysAmount: Number(customerPaysAmount).toFixed(2),
    fees: Number(fees).toFixed(2),
    giftName:
      giftName ||
      (values.giftFirstName
        ? `${values.giftFirstName} ${values.giftLastName}`
        : ''),
  });
};

export { handleSquareSubmit, handleStripeSubmit, handleCloverSubmit };
