import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  withStyles,
  Button,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { CustomImgCard } from '../../custom';
import defaultCardImage from '../../../images/defaultGiftCard.jpg';
import SocialShareSection from './SocialShareSection';
import { isIos, handleIOSMsg } from '../../../utils';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);

// BREAK DOWN THIS COMPONENT

const TransactionDetails = ({
  formData,
  business,
  orderNumber,
  orgId,
  cardDetails,
}) => {
  const { t } = useTranslation();
  const isIOS = isIos();
  const {
    businessId,
    id,
    totalAmount,
    cardAmount,
    organizationName,
    giveBackAmount,
    orgCode,
    customerPaysAmount,
    fees,
  } = formData;
  const savedAmount =
    Number(totalAmount) + Number(fees) - Number(customerPaysAmount);
  const { name, isWhitelabel, currency, fees: configFees } = business;
  const imageUrl = cardDetails.imageUrl || formData.imageUrl;
  const handleBackToCardsIOS = () => {
    handleIOSMsg('payment_success');
  };
  const handleBuyMoreCards = () => {
    // TODO: rethink
    if (isWhitelabel) {
      if (orgId) {
        navigate(`/card/${id || businessId}?org=${orgId}`, { replace: true });
      }
      navigate(`/card/${id || businessId}`, { replace: true });
      return;
    }
    if (orgId) {
      navigate(`/?org=${orgId}`, { replace: true });
      return;
    }
    navigate(`/card/${id || businessId}`, { replace: true });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          <b>
            {cardDetails.cardNumber && `${t('addFunds')}: `}
            {t('transactionSuccessful')}
          </b>
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          {t('orderNumber')} <b>{`${orderNumber}`} </b>
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('receiveEmail')}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          {t('giftCardPurchased')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <CustomImgCard
              name={name || 'default image'}
              imageUrl={imageUrl || defaultCardImage}
              elevation={3}
              variant="elevation"
              width="100%"
            />
            {!!organizationName && (
              <Box>
                <SocialShareSection
                  organizationName={organizationName}
                  orgCode={orgCode}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box width="100%" pt={1}>
              <TableContainer>
                <Table aria-label="Charge breakdown">
                  <TableBody>
                    {cardDetails.balance && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {t('newGiftCardBalance')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            display="block"
                            align="right"
                            variant="body2"
                          >
                            <b>
                              {t('cardValue', {
                                amount:
                                  cardDetails.balance + Number(cardAmount),
                                formatParams: {
                                  amount: { currency },
                                },
                              })}
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {cardDetails.balance
                            ? t('addedBalance')
                            : t('giftCardValue')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {t('cardValue', {
                          amount: cardAmount,
                          formatParams: {
                            amount: { currency },
                          },
                        })}
                      </TableCell>
                    </TableRow>
                    {savedAmount > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            style={{ color: 'green' }}
                          >
                            {t('youSaved')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            style={{ color: 'green' }}
                          >
                            {t('cardValue', {
                              amount: savedAmount,
                              formatParams: {
                                amount: { currency },
                              },
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {fees > 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('fees', { fees: configFees })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {t('cardValue', {
                            amount: fees,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">
                          <b>{t('amountCharged')}</b>
                        </Typography>{' '}
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          {t('cardValue', {
                            amount: customerPaysAmount || totalAmount,
                            formatParams: {
                              amount: { currency },
                            },
                          })}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {organizationName && (
              <Box py={2}>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ color: '#848418' }}
                  component="p"
                >
                  <b>
                    {t('yourSocialImpact')} ={' '}
                    {t('cardValue', {
                      amount: giveBackAmount,
                      formatParams: {
                        amount: { currency },
                      },
                    })}{' '}
                    {t('willBeGivenTo')} {`${organizationName}`}
                  </b>
                </Typography>
              </Box>
            )}
            {isIOS && (
              <Box pb={2}>
                <Button
                  fullWidth
                  onClick={handleBackToCardsIOS}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t('backToCards')}
                </Button>
              </Box>
            )}

            <Box pb={2} align="center">
              <Button
                onClick={handleBuyMoreCards}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                {t('buyMoreCard')}
              </Button>
            </Box>
          </Grid>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Grid item xs={12} sm={3} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

TransactionDetails.defaultProps = {
  orgId: '',
  cardDetails: {},
};

TransactionDetails.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    currency: PropTypes.string,
    giveBack: PropTypes.number,
    fees: PropTypes.number,
    isWhitelabel: PropTypes.bool,
  }).isRequired,
  formData: PropTypes.shape({
    businessId: PropTypes.string,
    id: PropTypes.string,
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    organizationName: PropTypes.string,
    cardAmount: PropTypes.string,
    transactionFee: PropTypes.string,
    giveBackAmount: PropTypes.string,
    orgCode: PropTypes.number,
    fees: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  orgId: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
};

export default TransactionDetails;
