import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import i18n from 'i18next';
import { PhotoCamera } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { businesses, alert, checkCardBalance, giftCards } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import GiftCardScanner from '../../custom/GiftCardScanner';
import OtpDialog from './OtpDialog';

const RedemptionForm = ({
  amount,
  openAlert,
  getCheckCardBalance,
  setCardDetails,
  cardDetails,
  partner,
  business,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [showScanner, setShowScanner] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const dispatch = useDispatch();
  const otp = localStorage.getItem('otp');
  const [currencySymbol] = getSymbolFromCurrency(cardDetails.currency || 'USD');
  const fetchBalance = async (card) => {
    const normalizedCardNumber = card.split(' ').join('');
    const res = await getCheckCardBalance({
      partner,
      card: normalizedCardNumber,
      businessId: business.id,
    });

    if (res.payload && res.payload.businessId === business.id) {
      const {
        balance,
        currency,
        businessId,
        firstName,
        lastName,
        email,
        giftFirstName,
        giftLastName,
        giftEmail,
        culture,
      } = res.payload;
      setCardDetails({
        balance,
        currency,
        cardNumber: normalizedCardNumber,
        businessId,
        firstName,
        lastName,
        email,
        giftFirstName,
        giftLastName,
        giftEmail,
        culture,
      });
      i18n.changeLanguage(culture);
    } else {
      openAlert({
        message:
          'Card does not exist or something went wrong. Please try again later.',
        severity: 'error',
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={null}
      initialValues={{
        gan: cardDetails.cardNumber,
        otp,
        businessId: business.id,
        amount,
      }}
      onSubmit={async (values, actions, setFieldValue) => {
        actions.setSubmitting(true);
        const payload = { ...values, gan: values.gan.replace(' ', '') };
        const response = await dispatch(giftCards.actions.gcRedeem(payload));
        if (response.payload?.otp) {
          setShowOtpPopup(true);
        } else {
          localStorage.setItem('otp', values.otp);
        }
        if (response.error) {
          openAlert({
            message:
              response.payload?.message ||
              t('weCannotProcessPaymentsPleaseTryLater'),
            severity: 'error',
          });
        } else if (response.payload) {
          if (!response.payload?.otp) {
            openAlert({
              message: t('Charge Successful'),
              severity: 'success',
            });
            setFieldValue('gan', '');
          }
        }
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        const setGan = (val) => {
          if (val) {
            setFieldValue('gan', val);
            setShowScanner(false);
            handleSubmit();
          }
        };

        const setOtp = (val) => {
          setFieldValue('otp', val);
          setShowOtpPopup(false);
          handleSubmit();
        };

        return (
          <>
            <Form id="check-balance-form" onSubmit={handleSubmit}>
              <Box pt={4} pb={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={3}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      label={t('amount')}
                      startAdornment={currencySymbol}
                      name="amount"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      label={t('giftCardNumber')}
                      name="gan"
                      variant="outlined"
                      required
                      fullWidth
                      disabled={isSubmitting}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={setShowScanner}
                      style={{ padding: 10 }}
                    >
                      <PhotoCamera />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box pb={mobile ? 2 : 10}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!values.amount || !values.gan || isSubmitting}
                      onClick={handleSubmit}
                      size="large"
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('charge')
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
            <GiftCardScanner
              setCode={setGan}
              open={showScanner}
              close={() => setShowScanner(false)}
              checkGiftCard={fetchBalance}
            />
            <OtpDialog
              open={showOtpPopup}
              close={() => setShowOtpPopup(false)}
              onSubmit={setOtp}
            />
          </>
        );
      }}
    </Formik>
  );
};

RedemptionForm.defaultProps = {
  amount: '',
  partner: '',
};
RedemptionForm.propTypes = {
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  amount: PropTypes.number,
  openAlert: PropTypes.func.isRequired,
  setCardDetails: PropTypes.func.isRequired,
  getCheckCardBalance: PropTypes.func.isRequired,
  partner: PropTypes.string,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    partner: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    logoUrl: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  isLoading: businesses.selectors.selectIsLoading(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  setCardDetails: (payload) =>
    dispatch(checkCardBalance.actions.setCardDetails(payload)),
  getCheckCardBalance: (payload) => {
    return dispatch(checkCardBalance.actions.balance(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionForm);
