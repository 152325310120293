import React from 'react';
import { Grid, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import CheckoutCloverDemo from './CheckoutCloverDemo';

import OrderDetails from './OrderDetails';

import PoweredByClover from '../../../images/poweredByClover.png';

import { Header, Content } from '../../layouts';
import SEO from '../../seo';
import { businesses } from '../../../state';

const CardRedemptionDemo = ({ fetchBusiness, id, business, isLoading }) => {
  const useStyles = makeStyles((theme) => ({
    squareLogo: {
      height: '20px',
    },
    stripeLogo: {
      maxHeight: '30px',
    },
    cloverLogo: {
      maxHeight: '20px',
    },
    item1: {
      order: 1,
      [theme.breakpoints.up('sm')]: {
        order: 2,
      },
    },
    item2: {
      order: 2,
      [theme.breakpoints.up('sm')]: {
        order: 1,
      },
    },
    textColor: {
      '& *:not(.Mui-error):not(button):not(button span)': {
        color: business.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  const [demoAmount, setDemoAmount] = React.useState(68.36);
  const [redeemBalanceAmount, setRedeemBalanceAmount] = React.useState(0);
  const [merchantId, setMerchantId] = React.useState(null);

  const [giftCardNumber, setGiftCardNumber] = React.useState('');
  React.useEffect(() => {
    (async () => {
      await fetchBusiness(id).then(({ error }) => {
        if (!isEmpty(error)) {
          navigate('/');
        }
      });
    })();
  }, []);
  return (
    <Box className={classes.textColor}>
      <SEO title="Checkout" />
      <Header logo bgcolor={business.brandColors?.headerColor}>
        <div />
      </Header>
      <Divider />
      <Box bgcolor={business.brandColors?.formColor}>
        <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
          <Box pb={4}>
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box my={3}>
                <Typography display="block" align="center" variant="h5">
                  {isLoading ? <Skeleton width="100%" /> : business?.name}
                </Typography>
                <Typography display="block" align="center" variant="subtitle1">
                  Confirm your order details and enter your billing information
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={6}
            >
              <Grid item xs={12} sm={7} className={classes.item2}>
                <CheckoutCloverDemo
                  demoAmount={demoAmount}
                  setDemoAmount={setDemoAmount}
                  merchantId={merchantId}
                  redeemBalanceAmount={redeemBalanceAmount}
                  giftCardNumber={giftCardNumber}
                />
                <Box py={2} align="center">
                  <img
                    src={PoweredByClover}
                    alt="Powered By Clover"
                    className={classes.squareLogo}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} className={classes.item1}>
                <OrderDetails
                  demoAmount={demoAmount}
                  setDemoAmount={setDemoAmount}
                  redeemBalanceAmount={redeemBalanceAmount}
                  setRedeemBalanceAmount={setRedeemBalanceAmount}
                  setMerchantId={setMerchantId}
                  setGiftCardNumber={setGiftCardNumber}
                />
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Box>
      {/* <Divider /> */}
      {/* <Footer /> */}
    </Box>
  );
};
CardRedemptionDemo.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    culture: PropTypes.string,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};
const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  isLoading: businesses.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardRedemptionDemo);
