import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import { navigate as navigateGatsby } from 'gatsby';
import i18n from 'i18next';
import { PhotoCamera } from '@material-ui/icons';
import ReCaptcha from '../../ReCaptcha';
import { businesses, alert, checkCardBalance } from '../../../state';
import CheckBalanceSchema from './validation';
import CustomInputField from '../../custom/CustomInputField';
import CheckInScanner from '../../custom/CheckInScanner';

const CheckCardBalanceForm = ({
  cardNumberPassed,
  openAlert,
  getCheckCardBalance,
  setCardDetails,
  cardDetails,
  partner,
  business,
}) => {
  const recaptcha = React.createRef();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchBalance = async (card) => {
    const normalizedCardNumber = card.split(' ').join('');
    const res = await getCheckCardBalance({
      partner,
      card: normalizedCardNumber,
      businessId: business.id,
    });

    if (res.payload && res.payload.giftCardConfigId === business.id) {
      const {
        balance,
        currency,
        businessId,
        firstName,
        lastName,
        email,
        giftFirstName,
        giftLastName,
        giftEmail,
        culture,
        imageUrl,
        giftCardConfigId,
        occasionId,
        giftCardImageId,
        giftCardTermId,
        storeCreditId,
        languageId,
      } = res.payload;
      setCardDetails({
        balance,
        currency,
        cardNumber: normalizedCardNumber,
        giftCardConfigId,
        businessId,
        firstName,
        lastName,
        email,
        giftFirstName,
        giftLastName,
        giftEmail,
        culture,
        imageUrl,
        occasionId,
        giftCardImageId,
        giftCardTermId,
        storeCreditId,
        languageId,
      });
      i18n.changeLanguage(culture);
    } else {
      openAlert({
        message:
          'Card does not exist or something went wrong. Please try again later.',
        severity: 'error',
      });
    }
  };

  React.useEffect(() => {
    setIsLoading(true);

    const fn = async () => {
      if (cardNumberPassed) {
        fetchBalance(cardNumberPassed);
        setIsLoading(false);
      }
    };

    fn();
  }, []);

  if (isLoading && cardNumberPassed) {
    return <CircularProgress size={24} />;
  }
  const [showScanner, setShowScanner] = useState(false);

  return (
    <Formik
      enableReinitialize
      validationSchema={() => CheckBalanceSchema()}
      initialValues={{
        cardNumber: cardDetails.cardNumber || '',
        balance: cardDetails.balance || '',
      }}
      onSubmit={async (values, actions) => {
        fetchBalance(values.cardNumber);
        const isHuman = await recaptcha.current.props.grecaptcha.getResponse();
        if (isHuman) {
          actions.setSubmitting(true);

          fetchBalance(values.cardNumber);
          recaptcha.current.props.grecaptcha.reset();
          return;
        }
        recaptcha.current.execute();
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        const onResolved = () => {
          handleSubmit();
        };

        const setCode = (val) => {
          if (val) {
            setFieldValue('cardNumber', val);
            setShowScanner(false);
            handleSubmit();
          }
        };

        const handleAddBalance = async () => {
          await handleSubmit();
          if (cardDetails.giftCardConfigId) {
            navigateGatsby(`/add-funds/${cardDetails.giftCardConfigId}`);
          } else if (cardDetails.businessId) {
            navigateGatsby(`/add-funds/${cardDetails.businessId}`);
          }
        };
        return (
          <Form id="check-balance-form" onSubmit={handleSubmit}>
            <Box pt={4} pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid container item xs={12} md={7}>
                  <Grid
                    item
                    xs="auto"
                    style={{ flex: 'auto', marginRight: 15 }}
                  >
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      label={t('giftCardNumber')}
                      name="cardNumber"
                      variant="outlined"
                      required
                      fullWidth
                      disabled={isSubmitting}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid item alignItems="flex-end" textAlign="center">
                      <Button
                        variant="outlined"
                        onClick={() => setShowScanner(true)}
                        style={{ padding: 10 }}
                      >
                        <PhotoCamera />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    label={t('cardBalance')}
                    startAdornment={t('cardValue', {
                      amount: values.balance,
                      formatParams: {
                        amount: { currency: business.currency },
                      },
                    })}
                    value=""
                    name="balance"
                    variant="outlined"
                    fullWidth
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={mobile ? 2 : 10}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={7}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('checkBalance')
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleAddBalance}
                    disabled={isSubmitting || cardDetails.storeCreditId}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('addFunds')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <ReCaptcha onResolved={onResolved} createRef={recaptcha} />
            <CheckInScanner
              setCode={setCode}
              open={showScanner}
              close={() => setShowScanner(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

CheckCardBalanceForm.defaultProps = {
  cardNumberPassed: '',
  partner: '',
};
CheckCardBalanceForm.propTypes = {
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    giftCardConfigId: PropTypes.string,
    businessId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    occasionId: PropTypes.string,
    giftCardImageId: PropTypes.string,
    giftCardTermId: PropTypes.string,
    storeCreditId: PropTypes.string,
    languageId: PropTypes.string,
  }).isRequired,
  cardNumberPassed: PropTypes.string,
  openAlert: PropTypes.func.isRequired,
  setCardDetails: PropTypes.func.isRequired,
  getCheckCardBalance: PropTypes.func.isRequired,
  partner: PropTypes.string,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    partner: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    logoUrl: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  isLoading: businesses.selectors.selectIsLoading(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  setCardDetails: (payload) =>
    dispatch(checkCardBalance.actions.setCardDetails(payload)),
  getCheckCardBalance: (payload) => {
    return dispatch(checkCardBalance.actions.balance(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckCardBalanceForm);
