import React from 'react';
import { Box, Card, CardMedia, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { tickets } from '../../../state';

const TicketImgCard = () => {
  const useStyles = makeStyles((theme) => ({
    giveBackBox: {
      minWidth: '50px',
      minHeight: 'auto',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 3,
      borderTopLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    card: {
      position: 'relative',
      borderRadius: '16px',
    },
    cardMedia: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 'calc(56.50% + 1px)',
      backgroundSize: 'contain',
      borderRadius: '16px',
    },
    cardMediaXS: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 'calc(60.50% + 1px)',
      backgroundSize: 'contain',
      borderRadius: '16px',
    },
    standAloneCard: {
      width: '100%',
      backgroundSize: 'contain',
    },
    square: {
      borderRadius: '0',
    },
    fontGilmerBold: {
      fontFamily: 'gilmer-bold',
    },
    fontNarcost: {
      fontFamily: 'narcost-regular',
    },
    fontGilmer: {
      fontFamily: 'gilmer',
    },
  }));
  const classes = useStyles();

  const { imageUrl, name, title, tagLine, startTime } = useSelector(
    tickets.selectors.selectTicketEvent
  );
  return (
    <>
      <Card style={{ boxShadow: 'none', padding: 8 }}>
        <CardMedia alt={name} image={imageUrl} className={classes.cardMedia} />
        <Box m={2}>
          <Typography variant="h4" className={classes.fontNarcost}>
            {title}
          </Typography>
          <Typography className={classes.fontGilmerBold}>{tagLine}</Typography>
          <Typography className={classes.fontGilmer} style={{ marginTop: 10 }}>
            {moment(`${startTime}Z`).format('ddd, MMM DD, YYYY h:mm A')}
          </Typography>
        </Box>
      </Card>
    </>
  );
};

export default TicketImgCard;
