import React, { useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';
import {
  TextField as MUITextField,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Button,
  // Link,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'formik-material-ui-lab';
import { Skeleton } from '@material-ui/lab';
import { getOr, isEmpty } from 'lodash/fp';
import { navigate } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import ReCaptcha from '../../ReCaptcha';

import { businesses, organizations, checkout } from '../../../state';
import UserInfoSchema from './validation';
import CustomInputField from '../../custom/CustomInputField';
import {
  handleSquareSubmit,
  handleStripeSubmit,
  handleCloverSubmit,
} from './PosSubmits';
import currencySymbols from '../../../../misc/currencySymbols';

const useStyles = makeStyles((theme) => ({
  btn: {
    borderColor: theme.palette.light,
    flexGrow: 1,
  },
  skeleton: {
    marginRight: '5px',
    height: '70px',
    width: '20%',
  },
}));

// TODO: return alert snackbar
const UserInfoForm = ({
  organization,
  getSecret,
  allOrganizations,
  setFormData,
  isLoading,
  getTotals,
}) => {
  const { t } = useTranslation();
  // const recaptcha = React.createRef();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [customAmount, setCustomAmount] = React.useState(false);
  const business = useSelector(businesses.selectors.selectBusiness);
  // const formData = useSelector(checkout.selectors.selectFormData);

  const charLimit = 255;
  const cardValues = getOr([], 'amounts', business);
  const isWhitelabel = getOr(false, 'isWhitelabel', business);
  const minCardAmount = cardValues[0];
  const {
    id,
    businessId,
    giftCardImageId,
    allowCustomAmount,
    minAmount,
    maxAmount,
    partner,
    currency,
    country,
    giveBack,
    discounts,
    customAmountDiscount,
    occasions,
    fees,
    feesHelperText,
  } = business;
  const isBhnPartner = (getOr([], 'partner', business) || []).includes('BHN');
  const hasOrgId = organization.id;
  const handleBack = () => {
    return !isEmpty(organization)
      ? navigate(`/?org=${organization.id}`)
      : navigate(`/`);
  };
  const formikRef = useRef();

  useEffect(() => {
    if (businessId && business && !giftCardImageId) {
      const filteredOccasions = occasions
        .map((o) => ({
          ...o,
          images: o.images.filter((i) => i.status === 0),
        }))
        .filter((o) => o.status === 0 && o.images.length > 0);
      if (
        filteredOccasions &&
        filteredOccasions.length === 1 &&
        filteredOccasions[0].images.length === 1
      ) {
        formikRef.current.setFieldValue(
          'imageUrl',
          filteredOccasions[0].images[0].url
        );
        formikRef.current.setFieldValue('occasionId', filteredOccasions[0].id);
        formikRef.current.setFieldValue(
          'giftCardImageId',
          filteredOccasions[0].images[0].id
        );
        formikRef?.current?.setFieldValue(
          'languageId',
          filteredOccasions[0]?.languageId
        );
      }
      // recaptcha.current.execute();
    }
  }, [business]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      validationSchema={() =>
        UserInfoSchema(maxAmount, minAmount, customAmount, currency)
      }
      initialValues={{
        businessId: business.businessId || business.id || '',
        id: business.id || '',
        occasionId: business.occasionId || '',
        giftCardImageId: business.giftCardImageId || '',
        organizationId: organization.id || undefined,
        organizationName: organization.name || undefined,
        orgCode: organization.code || null,
        email: '',
        firstName: '',
        lastName: '',
        promoCode: '',
        isGift: false,
        amount: minCardAmount,
        giftFirstName: '',
        giftLastName: '',
        giftEmail: '',
        giftMessage: '',
        languageId: '',
        termId: '',
        currency: currency || 'USD',
        country: country || 'US',
        fees: Number(fees).toFixed(2),
        feesHelperText,
        sendNow: 1,
        deliverAt: '',
        locationId: business.locationId,
        imageUrl: business.imageUrl ?? '',
      }}
      onSubmit={async (values, actions) => {
        // const isHuman = await recaptcha.current.props.grecaptcha.getResponse();
        // if (isHuman) {
        // eslint-disable-next-line no-param-reassign
        values.deliverAt = values.deliverAt
          ? moment(values.deliverAt)
              .startOf('day')
              .add(9, 'hours')
              .local()
              .format()
          : null;
        actions.setSubmitting(true);
        if (partner === 'Square') {
          await handleSquareSubmit(values, getTotals, setFormData);
        }
        if (partner === 'BHN') {
          await handleSquareSubmit(values, getTotals, setFormData);
        }
        if (partner === 'Stripe') {
          await handleStripeSubmit(values, getSecret, setFormData);
        }
        if (partner === 'Clover') {
          await handleCloverSubmit(values, getTotals, setFormData);
        }
        // recaptcha.current.props.grecaptcha.reset();
        if (!isEmpty(organization)) {
          navigate(`/card/${id}/checkout?org=${organization.id}`);
        } else {
          navigate(`/card/${id}/checkout`);
        }
        // return;
        // }
        // recaptcha.current.execute();
        actions.setSubmitting(false);
      }}
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        handleSubmit,
        errors,
        setFieldTouched,
      }) => {
        const { amount, isGift, organizationName, giftMessage } = values;

        const handleCardValueClick = (cardValue) => {
          setFieldValue('amount', cardValue);
          setFieldValue('customAmount', false);
          setCustomAmount(false);
        };

        const handleCustomAmountChange = (e) => {
          setFieldValue('amount', e.target.value);
        };

        const handleRadioChange = (event) => {
          setFieldValue('sendNow', event.target.value);
          if (event.target.value === '0') {
            const date = moment().add(1, 'days').format('MM/DD/YYYY');
            setFieldValue('deliverAt', date);
          } else {
            setFieldValue('deliverAt', '');
          }
        };

        return (
          <Form>
            <Box
              pt={2}
              pb={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">{t('giftCardAmount')}</Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="nowrap"
              pb={2}
              width="100%"
              justifyContent="center"
            >
              {isLoading ? (
                <>
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton className={classes.skeleton} />
                  <Skeleton height="70px" width="20%" />
                </>
              ) : (
                <ToggleButtonGroup
                  id="amount"
                  aria-label="Select card value"
                  style={{ width: '100%' }}
                >
                  {cardValues.map(
                    (cardValue, i) =>
                      cardValue && (
                        <ToggleButton
                          variant="contained"
                          color="primary"
                          selected={cardValue === amount}
                          onClick={() => handleCardValueClick(cardValue)}
                          key={cardValue}
                          value={cardValue}
                          aria-label={cardValue}
                          className={classes.btn}
                        >
                          <Box display="flex" flexDirection="column">
                            {!isEmpty(discounts) && discounts[i] < cardValue ? (
                              <>
                                <Box>
                                  {t('cardValue', {
                                    amount: discounts[i],
                                    formatParams: {
                                      amount: { currency },
                                    },
                                  })}
                                </Box>
                                <Typography
                                  variant="caption"
                                  style={{
                                    textTransform: 'none',
                                    lineHeight: '1.25rem',
                                    display: 'inline-block',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                  }}
                                >
                                  {t('actualValue')}
                                  <br />
                                  {t('cardValue', {
                                    amount: cardValue,
                                    formatParams: {
                                      amount: { currency },
                                    },
                                  })}
                                  <br />
                                  {`(${(
                                    ((cardValue - discounts[i]) / cardValue) *
                                    100
                                  ).toFixed(1)}% ${t('off')})`}
                                </Typography>
                              </>
                            ) : (
                              t('cardValue', {
                                amount: cardValue,
                                formatParams: {
                                  amount: { currency },
                                },
                              })
                            )}
                          </Box>
                        </ToggleButton>
                      )
                  )}
                  {allowCustomAmount && (
                    <ToggleButton
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      size="small"
                      selected={customAmount === true}
                      onClick={() => {
                        setFieldValue('amount', '');
                        setFieldValue('customAmount', false);
                        setCustomAmount(true);
                      }}
                      key="custom"
                      value="custom"
                      aria-label="custom"
                    >
                      <Box display="flex" flexDirection="column">
                        <Box>{t('custom')}</Box>
                        {!!customAmountDiscount && (
                          <Typography variant="caption">
                            {`(${(customAmountDiscount * 100).toFixed(1)}% ${t(
                              'off'
                            )})`}
                          </Typography>
                        )}
                      </Box>
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              )}
            </Box>

            {allowCustomAmount && customAmount && (
              <Box pb={2}>
                <CustomInputField
                  customInput={Field}
                  component={TextField}
                  fullWidth
                  name="amount"
                  label={t('enterCustomAmount')}
                  variant="outlined"
                  trimLeadingZeros
                  onChange={handleCustomAmountChange}
                  startAdornment={
                    currencySymbols[currency]?.symbol_native || '$'
                  }
                />
              </Box>
            )}
            {!!giveBack && (
              <>
                <Box pb={2}>
                  {!hasOrgId ? (
                    <Field
                      component={Autocomplete}
                      name="organizationId"
                      value={undefined}
                      autoHighlight
                      options={allOrganizations}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      clearOnBlur
                      clearOnEscape
                      selectOnFocus
                      freeSolo
                      disabled={hasOrgId || isSubmitting}
                      onChange={(e, org) => {
                        setFieldValue('organizationId', org ? org.id : '');
                        setFieldValue('organizationName', org ? org.name : '');
                        setFieldValue('orgCode', org ? org.code : '');
                      }}
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          label={t('supportedNonprofitStartTyping')}
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      fullWidth
                      disabled
                      name="organizationName"
                      label={t('supportedNonprofit')}
                      variant="outlined"
                      InputProps={{ value: organizationName || '' }}
                    />
                  )}
                </Box>
                <Box pb={2} display="flex" alignItems="center">
                  {!isBhnPartner && (
                    <Typography variant="caption">
                      {t('noteThreeCreditCardProcessingFee')}
                    </Typography>
                  )}
                </Box>
              </>
            )}
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label={t('yourFirstName')}
                    name="firstName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label={t('yourLastName')}
                    name="lastName"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                label={t('yourEmail')}
                name="email"
                variant="outlined"
              />
            </Box>
            <Box pb={2} display="flex" alignItems="center">
              <Box mr="3">
                <Field
                  type="checkbox"
                  component={Checkbox}
                  color="primary"
                  name="isGift"
                />
              </Box>
              <Typography variant="caption">{t('sendAsGift')}</Typography>
            </Box>
            {isGift && (
              <>
                <Box pb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        fullWidth
                        required
                        label={t('recipientFirstName')}
                        name="giftFirstName"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        label={t('recipientLastName')}
                        name="giftLastName"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        label={t('recipientEmail')}
                        name="giftEmail"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid container item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      label={t('personalMessage')}
                      name="giftMessage"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{
                        maxLength: charLimit,
                      }}
                      helperText={`${giftMessage.length}/${charLimit}`}
                    />
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                      >
                        <Grid container item spacing={1} alignItems="center">
                          <Grid item>
                            <FormControlLabel
                              value="1"
                              control={<Radio color="primary" />}
                              label={
                                <Typography variant="body1" component="span">
                                  {t('sendNow')} <br />
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="primary" />}
                              label={
                                <Typography variant="body1" component="span">
                                  {t('sendLater')}
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                    {values.sendNow === '0' && (
                      <Grid item xs={6}>
                        <DatePicker
                          format="MMMM DD, YYYY"
                          disablePast
                          minDate={moment().add(1, 'days')}
                          required
                          slotProps={{
                            textField: {
                              readOnly: true,
                              variant: 'outlined',
                              error: Boolean(errors.deliverAt),
                              helperText: errors.deliverAt,
                            },
                          }}
                          value={
                            values.deliverAt ? moment(values.deliverAt) : null
                          }
                          onChange={(val) => {
                            setFieldValue(
                              'deliverAt',
                              val.isValid() ? val.format('MM/DD/YYYY') : ''
                            );
                          }}
                          name="deliverAt"
                          label={`${t('deliverAt')} *`}
                          onKeyPress={() => setFieldTouched('deliverAt')}
                          onClick={() => {
                            setFieldTouched('deliverAt');
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            )}
            <Box pb={mobile ? 2 : 10}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                {!isWhitelabel && (
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                      size="large"
                    >
                      {t('back')}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={isWhitelabel ? 12 : 6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={(!hasOrgId && isBhnPartner) || isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('continue')
                    )}
                  </Button>
                </Grid>
              </Grid>
              {/* <ReCaptcha onResolved={onResolved} createRef={recaptcha} /> */}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

UserInfoForm.defaultProps = {
  allOrganizations: [],
};

UserInfoForm.propTypes = {
  getSecret: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTotals: PropTypes.func.isRequired,
  allOrganizations: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  organization: organizations.selectors.selectOrganization(state),
  allOrganizations: organizations.selectors.selectOrganizations(state),
  isLoading: businesses.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSecret: (payload) => dispatch(checkout.actions.getSecret(payload)),
  setFormData: (payload) => dispatch(checkout.actions.setFormData(payload)),
  getTotals: (payload) => dispatch(checkout.actions.getTotals(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoForm);
