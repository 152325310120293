import * as Yup from 'yup';
import i18n from 'i18next';

const FreeItemRegisterSchema = () => {
  return Yup.object().shape({
    memberId: Yup.string().required(i18n.t('required')),
  });
};

export default FreeItemRegisterSchema;
