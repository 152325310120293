/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { FormHelperText, Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import CustomInputField from './CustomInputField';
import { countries } from '../../utils/constants';

const CountrySelect = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const onChangeCountryCode = (event) => {
    const phoneNumber =
      countries.find((c) => c.code === event.target.value)?.number +
      values.localPhone;
    setFieldValue('countryCode', event.target.value);
    onChange(phoneNumber);
  };

  const onChangeLocalPhone = (event) => {
    const phoneNumber =
      countries.find((c) => c.code === values.countryCode)?.number +
      event.target.value;
    onChange(phoneNumber);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Field
          component={FormikTextField}
          fullWidth
          select
          {...rest}
          value={value}
          name="countryCode"
          variant="outlined"
          onChange={onChangeCountryCode}
        >
          <MenuItem value={undefined}>---</MenuItem>
          {countries.map(({ name, number, code }) => (
            <MenuItem key={code} value={code}>
              {number} {name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={7}>
        <CustomInputField
          customInput={Field}
          component={FormikTextField}
          trim
          fullWidth
          required
          label={t('yourPhoneNumber')}
          name="localPhone"
          variant="outlined"
          onChange={onChangeLocalPhone}
        />
        {!errors.localPhone && errors.phone && touched.localPhone && (
          <FormHelperText error>{errors.phone}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CountrySelect;
