import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

import { businesses } from '../../../state';
import RedemptionForm from './RedemptionForm';
import SEO from '../../seo';
import { Header, Content, BusinessHeader, BusinessFooter } from '../../layouts';
import CardInfoSection from '../CardAddFunds/CardInfoSection';

const useStyles = makeStyles({
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  redemption: {
    position: 'absolute',
    left: '150%',
    top: '-11%',
    width: '200px',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

const Redemption = ({ business, location, fetchBusiness, ...nav }) => {
  const { id: routeId } = nav;
  const amount = Number(nav.amount);
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { isWhitelabel, logoUrl, website, partner } = business;

  React.useEffect(() => {
    (async () => {
      await fetchBusiness(routeId).then(({ error }) => {
        if (!isEmpty(error) && routeId) {
          navigate(`/card/${routeId}`);
        }
      });
    })();
  }, []);

  React.useEffect(() => {
    if (business?.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const merchantPintunaUrl = `/card/${routeId}`;
  return (
    <>
      <SEO title="Check Card Balance" />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
        />
      ) : (
        <Header logo />
      )}
      <Divider />
      <Content
        style={{ minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)' }}
      >
        <div className={classes.fsBackground} />
        <CardInfoSection showGiveBack={false} />
        <Box pb={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              {business?.id && (
                <RedemptionForm partner={partner} amount={amount} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Content>
      <BusinessFooter />
    </>
  );
};

Redemption.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    imageUrl: PropTypes.string,
    culture: PropTypes.string,
    partner: PropTypes.string,
  }).isRequired,
  fetchBusiness: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redemption);
