import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider } from '@material-ui/core';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, memberships, organizations } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter, Content } from '../../layouts';
import MembershipRegisterForm from './MembershipRegisterForm';
import MembershipUpdateForm from './MembershipUpdateForm';

const MembershipRegister = ({
  fetchBusiness,
  fetchOrganization,
  business,
  location,
  fetchOrganizations,
  ...nav
}) => {
  const { i18n } = useTranslation();
  const { id: routeId, membershipId } = nav;
  const dispatch = useDispatch();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: membershipConfig.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  React.useEffect(() => {
    const fn = async () => {
      const membershipConfigRes = await dispatch(
        memberships.actions.getMembershipConfig({ id: routeId })
      );

      if (membershipConfigRes?.error) {
        return navigate('/');
      }
      return null;
    };
    fn();
  }, []);

  React.useEffect(() => {
    if (membershipConfig && membershipConfig.culture) {
      i18n.changeLanguage(membershipConfig.culture);
      fetchBusiness(membershipConfig.businessId);
    }
  }, [membershipConfig]);

  const { businessName, logoUrl } = membershipConfig;
  return (
    <Box className={classes.textColor}>
      <SEO title={`Membership Sign Up | ${businessName}`} />
      {membershipConfig?.showHeader ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl=""
          merchantPintunaUrl=""
          bgcolor={membershipConfig.brandColors?.headerColor || '#eee'}
        />
      ) : (
        <Box
          mt={6}
          bgcolor={membershipConfig.brandColors?.headerColor || '#eee'}
        />
      )}
      <Box bgcolor={membershipConfig.brandColors?.backgroundColor || '#eee'}>
        <Container maxWidth="lg">
          <Content md={7} sm={10}>
            {membershipId ? (
              <MembershipUpdateForm routeId={routeId} />
            ) : (
              <MembershipRegisterForm routeId={routeId} />
            )}
          </Content>
        </Container>
      </Box>

      <Divider />
      <BusinessFooter bgcolor={membershipConfig.brandColors?.footerColor} />
    </Box>
  );
};

MembershipRegister.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    culture: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchMerchant(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipRegister);
