import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomImgCard } from '../../custom';
import comingSoon from '../../../images/comingsoon.jpg';
import thankyou from '../../../images/thankyou.jpg';

const useStyles = makeStyles({
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
});

const CardInfoSection = ({ success }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {success ? (
              <Box pt={2} display="block" align="center">
                <Typography variant="h5" color="primary">
                  {t('thankYouForYourReferral')}
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  {t('weWillContact')}
                </Typography>
              </Box>
            ) : (
              <Box pt={2} display="block" align="center">
                <Typography variant="h5" color="primary">
                  {t('comingSoon')}
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  {t('withLocalBusinesses')}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {success ? (
                <Box py={2}>
                  <CustomImgCard
                    name="Thank you card"
                    width="270px"
                    imageUrl={thankyou}
                  />
                </Box>
              ) : (
                <Box py={2}>
                  <CustomImgCard
                    name="Coming Soon card"
                    width="270px"
                    imageUrl={comingSoon}
                  />
                </Box>
              )}
            </Grid>
            {!success && (
              <Grid item xs={12}>
                <Typography
                  display="block"
                  align="center"
                  variant="body2"
                  component="div"
                >
                  We are actively signing up local businesses in your community.
                  If you would like to see your favorite local business on
                  Pintuna, go ahead and make the recommendation. We will be
                  happy to reach out to them.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CardInfoSection.propTypes = {
  success: PropTypes.bool.isRequired,
};

export default CardInfoSection;
