import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import QrScanner from 'react-qr-barcode-scanner';
import { alert, checkCardBalance } from '../../state';

const GiftCardScanner = (props) => {
  const { close, checkGiftCard, open, setCode } = props;
  const isLoading = useSelector(checkCardBalance.selectors.selectIsLoading);

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Scan Gift Card QR Code</DialogTitle>
      <Box py={2}>
        {open && (
          <QrScanner
            key="environmentQR"
            // facingMode={facingMode}
            onUpdate={(err, result) => {
              if (!isLoading && result?.text) {
                if (/^-?\d+$/.test(result.text)) {
                  checkGiftCard(result.text).then((res) => {
                    if (!res?.error) {
                      setCode(result.text);
                    } else {
                      alert.actions.open({
                        message:
                          'Card does not exist or something went wrong. Please try again later.',
                        severity: 'error',
                      });
                    }
                  });
                } else {
                  alert.actions.open({
                    message: 'Invalid gift card number',
                    severity: 'error',
                  });
                }
              }
            }}
            style={{ width: '100%' }}
          />
        )}
      </Box>
      <Box alignItems="center" justifyContent="center">
        <Button onClick={close} color="primary" variant="outlined">
          {isLoading ? <CircularProgress size={24} /> : 'Close'}
        </Button>
      </Box>
    </Dialog>
  );
};
GiftCardScanner.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  checkGiftCard: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default GiftCardScanner;
