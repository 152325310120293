import * as Yup from 'yup';
import i18n from 'i18next';

export const UserInfoSchema = (
  maxAmount,
  minAmount,
  balance,
  currency = 'USD'
) =>
  Yup.object().shape({
    isGift: Yup.boolean(),
    customAmount: Yup.boolean(),
    giftEmail: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string().email('Invalid email').required('Required'),
      otherwise: Yup.string(),
    }),
    giftFirstName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftLastName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    amount: Yup.number()
      .test({
        name: 'max-balance',
        message: () => {
          const max = 2000;
          const remaining = Math.max(max - balance, 0);

          return i18n.t('validation.maxBalance', {
            amount: i18n.t('cardValue', {
              amount: max,
              formatParams: { amount: { currency } },
            }),
            current: i18n.t('cardValue', {
              amount: balance,
              formatParams: { amount: { currency } },
            }),
            upto: i18n.t('cardValue', {
              amount: remaining,
              formatParams: { amount: { currency } },
            }),
          });
        },
        test(value) {
          return value + parseFloat(this.parent.balance || 0) <= 2000;
        },
      })
      .when('customAmount', {
        is: true,
        then: Yup.number()
          .typeError('Amount must be a number')
          .min(
            minAmount,
            `Minimum amount allowed is ${i18n.t('cardValue', {
              amount: minAmount,
              formatParams: {
                amount: { currency },
              },
            })}`
          )
          .max(
            maxAmount,
            `Maximum amount allowed is ${i18n.t('cardValue', {
              amount: maxAmount,
              formatParams: {
                amount: { currency },
              },
            })}`
          )
          .required('Required'),
      }),
  });

export const CheckReloadSchema = (maxAmount, minAmount) =>
  Yup.object().shape({
    cardNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    amount: Yup.number()
      .typeError('Amount must be a number')
      .min(minAmount, `Minumum amount $${minAmount}`)
      .max(maxAmount, `Maximum amount allowed is $${maxAmount}`)
      .required('Required'),
  });
