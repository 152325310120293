import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Divider,
} from '@material-ui/core';
import { Add } from '@material-ui/icons/';
import RedemptionField from './RedemptionField';

const OrderDetails = ({
  setDemoAmount,
  demoAmount,
  setRedeemBalanceAmount,
  setMerchantId,
  redeemBalanceAmount,
  setGiftCardNumber,
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <Box mb={2}>
          <Card raised>
            <CardContent>
              <Box pb={2}>
                <Typography
                  display="block"
                  color="textSecondary"
                  variant="body2"
                >
                  Order Summary
                </Typography>
              </Box>
              <Box pb={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">Sweet Potato Fries</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display="block" align="right" variant="body2">
                      1 x $7.49
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pb={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">Cheese Calzone</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display="block" align="right" variant="body2">
                      3 x $13.99
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pb={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      Hawaiian Gourmet Pizza
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display="block" align="right" variant="body2">
                      1 x $7.99
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" flexWrap="nowrap">
                      <Box mr={2}>
                        <IconButton size="small">
                          <Add />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        Add an order note
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Card raised>
          <CardContent>
            <Box pb={1}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Subtotal</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" align="right" variant="body2">
                    $57.45
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box pb={1}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Tip (10%)</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" align="right" variant="body2">
                    $5.74
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box pb={1}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Taxes (California)</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" align="right" variant="body2">
                    $5.17
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {redeemBalanceAmount !== 0 && (
              <Box pb={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">Gift Card</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display="block" align="right" variant="body2">
                      ${redeemBalanceAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box pb={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <b>Order Total</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block" align="right" variant="body2">
                    <b>${demoAmount}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <RedemptionField
                setDemoAmount={setDemoAmount}
                demoAmount={demoAmount}
                setRedeemBalanceAmount={setRedeemBalanceAmount}
                setMerchantId={setMerchantId}
                setGiftCardNumber={setGiftCardNumber}
              />
            </Box>
            <Box pt={1}>
              <Typography variant="caption" color="textSecondary" component="p">
                Note: An email confirmation will be sent to john.doe@gmail.com
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

OrderDetails.propTypes = {
  setDemoAmount: PropTypes.func.isRequired,
  setRedeemBalanceAmount: PropTypes.func.isRequired,
  setMerchantId: PropTypes.func.isRequired,
  redeemBalanceAmount: PropTypes.number.isRequired,
  setGiftCardNumber: PropTypes.func.isRequired,
  demoAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default OrderDetails;
