import React from 'react';
import { Typography, Link, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box pb={2}>
        <Typography variant="caption">
          {t('agreeTerm')}{' '}
          <Link
            href="/legal/terms-of-use"
            underline="hover"
            target="_blank"
            color="initial"
          >
            {t('pintunaTerm')}
          </Link>
          ,{' '}
          <Link
            href="/legal/privacy-policy"
            underline="hover"
            target="_blank"
            color="initial"
          >
            {t('privacyPolicy')}
          </Link>{' '}
          {t('and')}{' '}
          <Link
            href="/legal/e-sign-consent"
            underline="hover"
            target="_blank"
            color="initial"
          >
            {t('esignConsent')}
          </Link>
          .
        </Typography>
      </Box>
      <Box pb={2}>
        <Typography variant="caption">{t('completingPuchase')}</Typography>
      </Box>
    </>
  );
};

export default TermsAndConditions;
