import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider } from '@material-ui/core';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, loyalties } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter, Content } from '../../layouts';
import LoyaltyRedeemForm from './LoyaltyRedeemForm';

const LoyaltyRedeem = ({ ...nav }) => {
  const { i18n } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const { id: routeId } = nav;
  const dispatch = useDispatch();
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const loyaltyConfig = useSelector(loyalties.selectors.selectProgram);
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: loyaltyConfig.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  React.useEffect(() => {
    const fn = async () => {
      const loyaltyProgram = await dispatch(
        loyalties.actions.getLoyaltyProgram({ id: routeId })
      );

      if (loyaltyProgram?.error) {
        return navigate('/');
      }
      dispatch(
        businesses.actions.fetchMerchant(loyaltyProgram.payload.businessId)
      );
      return null;
    };
    fn();
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { name, logoUrl } = business;
  const merchantPintunaUrl = `/card/${routeId}`;
  return (
    <Box className={classes.textColor}>
      <SEO title={`${name}`} />
      {!isLoading && (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl=""
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={loyaltyConfig.brandColors?.headerColor || '#eee'}
        />
      )}
      <Box bgcolor={loyaltyConfig.brandColors?.backgroundColor || '#eee'}>
        <Container maxWidth="lg">
          <Content md={6} sm={10}>
            <LoyaltyRedeemForm routeId={routeId} />
          </Content>
        </Container>
      </Box>
      <Divider />
      <BusinessFooter bgcolor={loyaltyConfig.brandColors?.footerColor} />
    </Box>
  );
};

export default LoyaltyRedeem;
