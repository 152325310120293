import isIos from './isIos';

export default (msg) => {
  // Broadcast this msg to for IOS devices

  if (isIos()) {
    window.webkit.messageHandlers.iosClickListener.postMessage(msg);
  }
  return null;
};
