import React from 'react';
import { Box, Grid, Divider, Button } from '@material-ui/core';
import { Link } from 'gatsby';

import CardInfoSection from './CardInfoSection';
import OnBoardBusinessForm from './OnBoardBusinessForm';

import SEO from '../../seo';
import { Header, Content, BusinessFooter } from '../../layouts';

const CardDetails = () => {
  const [success, setSuccess] = React.useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <SEO title="Coming Soon" />
      <Header logo />
      <Divider />
      <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
        <CardInfoSection success={success} />
        <Box pb={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              {success ? (
                <Box pt={4} display="block" align="center">
                  <Button
                    color="primary"
                    component={Link}
                    to="/"
                    href="/"
                    variant="contained"
                  >
                    Back to Gift Cards
                  </Button>
                </Box>
              ) : (
                <OnBoardBusinessForm handleSuccess={handleSuccess} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Content>
      <BusinessFooter />
    </>
  );
};

export default CardDetails;
