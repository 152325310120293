import React, { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';

import { Header, Content, BusinessFooter, BusinessHeader } from '../../layouts';
import { businesses, checkout, checkCardBalance } from '../../../state';
import SEO from '../../seo';

import TransactionDetails from './TransactionDetails';

const Confirmation = ({
  business,
  formData,
  orderNumber,
  location,
  cardDetails,
  ...nav
}) => {
  const dispatch = useDispatch();
  const { id: routeId } = nav;

  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(button):not(button span)': {
        color: business.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  if (!orderNumber) {
    navigate(`/card/${routeId}`, { replace: true });
    return null;
  }
  const { org: orgId } = queryString.parse(location.search);
  const { isWhitelabel, logoUrl, website, id } = business;
  const merchantPintunaUrl = !orgId
    ? `/card/${id}`
    : `/card/${id}?org=${orgId}`;

  useEffect(() => {
    dispatch(businesses.actions.fetchBusiness(routeId));
  }, []);

  return (
    <Box className={classes.textColor}>
      <SEO title="Confirmation" />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={business.brandColors?.headerColor}
        />
      ) : (
        <Header logo bgcolor={business.brandColors?.headerColor} />
      )}
      <Divider />
      <Box bgcolor={business.brandColors?.formColor}>
        <Content md={7} style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
          <Box py={2}>
            <TransactionDetails
              formData={formData}
              business={business}
              orderNumber={orderNumber}
              orgId={orgId}
              cardDetails={cardDetails}
            />
          </Box>
        </Content>
      </Box>
      <Divider />
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </Box>
  );
};
Confirmation.defaultProps = {
  business: {},
  formData: {},
  cardDetails: {},
};

Confirmation.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    giveBack: PropTypes.number,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }),
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    organizationName: PropTypes.string,
    cardAmount: PropTypes.string,
    transactionFee: PropTypes.string,
    giveBackAmount: PropTypes.string,
    orgCode: PropTypes.number,
  }),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  orderNumber: PropTypes.string.isRequired,
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  orderNumber: checkout.selectors.selectOrderNumber(state),
  formData: checkout.selectors.selectFormData(state),
  business: businesses.selectors.selectBusiness(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

export default connect(mapStateToProps)(Confirmation);
