import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { Check, Cancel, PhotoCamera } from '@material-ui/icons';
import moment from 'moment';
import useSound from 'use-sound';
import { CustomImgCard } from '../../custom';
import { memberships } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import CheckInScanner from '../../custom/CheckInScanner';
import beep1 from '../../../sounds/beep-1.mp3';
import beep7 from '../../../sounds/beep-7.mp3';

const CheckInForm = () => {
  const { t } = useTranslation();
  const [successSound] = useSound(beep7);
  const [failedSound] = useSound(beep1);
  const [showScanner, setShowScanner] = useState(false);
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const membership = useSelector(memberships.selectors.selectDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (membership.tier || membership.error) {
      setTimeout(() => dispatch(memberships.actions.resetMembership()), 5000);
    }
  }, [membership]);
  return (
    <Box
      borderRadius={20}
      border="1px solid #ccc"
      p={4}
      mb={4}
      bgcolor={membershipConfig.brandColors?.formColor || '#fff'}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box pt={2} display="block" align="center">
              <Typography variant="h5" color="primary">
                {isLoading ? (
                  <Skeleton width="190px" />
                ) : (
                  membershipConfig.businessName
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <CustomImgCard
                  isLoading={isLoading}
                  name={membershipConfig.businessName}
                  width="270px"
                  imageUrl={membershipConfig.imageUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={2}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {membership.tier ? (
                  <>
                    <Box mt={1} textAlign="center">
                      <Check style={{ color: 'green', fontSize: '150px' }} />
                    </Box>
                    <Box fontWeight={700} mb={3}>
                      <Typography display="block" align="center" variant="h4">
                        You are checked in!
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        {membership.firstName} {membership.lastName}
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        {moment(membership.checkInTime).format(
                          'MMMM DD, YYYY, h:mma'
                        )}
                      </Typography>
                    </Box>
                  </>
                ) : membership.error ? (
                  <Box color="warning">
                    <Box mt={1} textAlign="center">
                      <Cancel style={{ color: 'red', fontSize: '150px' }} />
                    </Box>
                    <Box fontWeight={700} mb={3}>
                      <Typography display="block" align="center" variant="h5">
                        Sorry we could not find your membership information.
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        Please contact the business team.
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography display="block" align="center" variant="h5">
                      Scan Your Membership Card to Check-In
                    </Typography>
                    <Formik
                      enableReinitialize
                      validationSchema={() =>
                        Yup.object().shape({
                          code: Yup.string().required('Code is required'),
                        })
                      }
                      initialValues={{
                        code: '',
                      }}
                      onSubmit={async ({ code }) => {
                        const payload = {
                          data: {
                            code,
                            businessId: membershipConfig.businessId,
                          },
                        };
                        const res = await dispatch(
                          memberships.actions.checkIn(payload)
                        );
                        if (!res.error) {
                          successSound();
                        } else {
                          failedSound();
                        }
                      }}
                    >
                      {({ isSubmitting, handleSubmit, setFieldValue }) => {
                        const setCode = (val) => {
                          if (val) {
                            setFieldValue('code', val);
                            setShowScanner(false);
                            handleSubmit();
                          }
                        };
                        return (
                          <Form>
                            <Box mt={3}>
                              <Grid container justifyContent="space-between">
                                <Grid
                                  item
                                  style={{ width: 'calc(100% - 90px)' }}
                                >
                                  <CustomInputField
                                    customInput={Field}
                                    component={TextField}
                                    fullWidth
                                    autoFocus
                                    name="code"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  alignItems="flex-end"
                                  textAlign="center"
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => setShowScanner(true)}
                                    style={{ padding: 10 }}
                                  >
                                    <PhotoCamera />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box
                              my={3}
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              alignContent="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  t('checkIn')
                                )}
                              </Button>
                            </Box>
                            <CheckInScanner
                              setCode={setCode}
                              open={showScanner}
                              close={() => setShowScanner(false)}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckInForm;
