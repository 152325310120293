const findDefaultType = (list) => {
  let defaultId = '';

  if (Array.isArray(list)) {
    list.forEach(({ id, isDefault }) => {
      if (isDefault === true) {
        defaultId = id;
      }
    });
  }

  return defaultId;
};

export default findDefaultType;
