/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { Box, Grid, Button, MenuItem, IconButton } from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { alert, memberships } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const initValues = {
  freeItemConfigId: '',
  quantity: 1,
  note: '',
};

const AddPerksForm = ({ isOpen, closeModal }) => {
  const membership = useSelector(memberships.selectors.selectDetails);

  const [loading, setLoading] = React.useState(false);
  const freeItemConfigs = useSelector(
    memberships.selectors.selectFreeItemConfig
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const [initialValues] = React.useState(initValues);
  const [maxQuantity, setMaxQuantity] = React.useState(0);

  useEffect(() => {
    if (membership?.id) {
      dispatch(
        memberships.actions.getMembershipFreeItemConfig(membership.plan?.id)
      );
    }
  }, [membership]);

  const validationSchema = Yup.object().shape({
    freeItemConfigId: Yup.string().required(t('required')),
    quantity: Yup.number()
      .min(0.00001, t('required'))
      .max(maxQuantity, `${t('maximumIs')}${maxQuantity}`)
      .required(t('required')),
    note: Yup.string().required(t('required')),
  });

  const getMaxQuantity = (key) => {
    if (!key || !freeItemConfigs) return null;
    const index = freeItemConfigs.findIndex((f) => f.id === key);
    return index > -1 ? freeItemConfigs[index].quantity : null;
  };

  const body = (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const formData = {
            ...values,
            membershipId: membership.id,
            quantity: Number(values.quantity),
          };

          try {
            const response = await dispatch(
              memberships.actions.createMembershipFreeItem(formData)
            );

            if (response.payload) {
              openAlert({
                message: t('membership.freeItemRegister.addedSuccessfully'),
                severity: 'success',
              });
              resetForm();
              dispatch(
                memberships.actions.getMembershipFreeItem(membership.id)
              );
              dispatch(
                memberships.actions.getMembershipFreeItemConfig(
                  membership.plan?.id
                )
              );
              closeModal(true);
            } else if (response.error) {
              openAlert({
                message:
                  response.error.message ||
                  t('membership.freeItemRegister.failCreate'),

                severity: 'error',
              });
            }
            setLoading(false);
          } catch (error) {
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ setFieldValue, handleSubmit }) => {
          const handleFreeItem = (event, val) => {
            setFieldValue('freeItemConfigId', val.props.value);
            setMaxQuantity(getMaxQuantity(val.props.value));
          };

          return (
            <Form>
              <Box mt={2}>
                <Grid container xs={12} alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.freeItemRegister.selectFreeItem')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      name="freeItemConfigId"
                      variant="outlined"
                      onChange={(event, val) => handleFreeItem(event, val)}
                      placeholder={t(
                        'membership.freeItemRegister.selectFreeItem'
                      )}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {freeItemConfigs.map(
                        (opt) =>
                          opt && (
                            <MenuItem value={opt.id} key={opt.id}>
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.freeItemRegister.quantity')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      type="number"
                      removeArrows
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="quantity"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography>
                      {t('membership.freeItemRegister.notes')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      removeArrows
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="note"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddPerksForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddPerksForm;
