import * as Yup from 'yup';
import i18n from 'i18next';

const UserInfoSchema = (maxAmount, minAmount, customAmount, currency) =>
  Yup.object().shape({
    isGift: Yup.boolean(),
    giftEmail: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .email(i18n.t('invalidEmail'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftFirstName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    giftLastName: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
      otherwise: Yup.string(),
    }),
    firstName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    lastName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('required')),
    amount: customAmount
      ? Yup.number()
          .typeError(i18n.t('amountMustBeANumber'))
          .min(
            minAmount,
            `${i18n.t('minumumAmount')} ${i18n.t('cardValue', {
              amount: minAmount,
              formatParams: {
                amount: { currency },
              },
            })}`
          )
          .max(
            maxAmount,
            `${i18n.t('maximumAmountAllowed')} ${i18n.t('cardValue', {
              amount: maxAmount,
              formatParams: {
                amount: { currency },
              },
            })}`
          )
          .required(i18n.t('required'))
      : Yup.number()
          .typeError(i18n.t('amountMustBeANumber'))
          .required(i18n.t('required')),
    deliverAt: Yup.string().when('isGift', {
      is: (isGift) => isGift === true,
      then: Yup.string().when('sendNow', {
        is: (sendNow) => sendNow === '0',
        then: Yup.string().required(i18n.t('required')),
      }),
      otherwise: Yup.string(),
    }),
  });

export default UserInfoSchema;
