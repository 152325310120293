import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { getOr, isEmpty } from 'lodash/fp';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
// import { loadStripe } from '@stripe/stripe-js';
import { TextField as FormikTextField } from 'formik-material-ui';
import {
  // Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';

import InputFrame from './InputFrame';
import handleIOSMsg from '../../../utils/IOSUtils/handleIOSMsg';
import CheckoutSchema from './validation';
import { checkout, organizations, alert } from '../../../state';
import AppleGooglePay from './AppleGooglePay';
import TermsAndConditions from './TermsAndConditions';
import listOfStates from '../../../../misc/listOfStates.json';

// const promise = loadStripe(process.env.GATSBY_STR_KEY);

const StripeForm = ({
  clientSecret,
  formData,
  organization,
  openAlert,
  isAddFunds,
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { totalAmount, email, firstName, lastName, currency } = formData;

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    if (event.error) {
      openAlert({
        message: event.error.message,
        severity: 'error',
      });
    }
  };

  return (
    <Formik
      validationSchema={CheckoutSchema}
      initialValues={{
        city: '',
        line1: '',
        state: '',
        postal_code: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const response = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              address: {
                ...values,
              },
              email,
              name: `${firstName} ${lastName}`,
            },
          },
        });
        const paymentIntent = getOr({}, 'paymentIntent', response);
        const err = getOr({}, 'error', response);
        const confirmationPath = isAddFunds
          ? '/add-funds/confirmation'
          : '/card/confirmation';
        if (paymentIntent) {
          const status = getOr('', ['paymentIntent', 'status'], response);
          if (status === 'succeeded') {
            const cvcEl = elements.getElement(CardCvcElement);
            const cardNum = elements.getElement(CardNumberElement);
            const cardExpDate = elements.getElement(CardExpiryElement);

            cvcEl.clear();
            cardNum.clear();
            cardExpDate.clear();

            actions.setSubmitting(false);
            setSucceeded(true);
            actions.resetForm();
            actions.setSubmitting(false);
            if (!isEmpty(organization)) {
              return navigate(`${confirmationPath}?org=${organization.id}`, {
                replace: true,
              });
            }
            return navigate(`${confirmationPath}`, { replace: true });
          }
        }
        if (err) {
          handleIOSMsg('payment_failed');

          openAlert({
            message: err.message,
            severity: 'error',
          });
        }
        return null;
      }}
    >
      {({ setFieldValue, isSubmitting, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Box pb={2}>
                  <Field
                    component={FormikTextField}
                    label={t('address')}
                    name="line1"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={FormikTextField}
                        label={t('city')}
                        name="city"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label={t('state')}
                        select
                        name="state"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            onClick={() => setFieldValue('state', state)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label={t('zipcode')}
                        name="postal_code"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            maxLength: '5',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={2}>
                  <Grid container item xs={12}>
                    <TextField
                      label={t('cardNumber')}
                      name="ccnumber"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: InputFrame,
                        inputProps: {
                          component: CardNumberElement,
                        },
                      }}
                    />
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <TextField
                        label={t('expirationDate')}
                        name="ccexp"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: InputFrame,
                          inputProps: {
                            component: CardExpiryElement,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={t('cvc')}
                        name="cvc"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: InputFrame,
                          inputProps: {
                            component: CardCvcElement,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box pb={2}>
                  <TermsAndConditions />
                </Box>
                <Button
                  type="submit"
                  disabled={isSubmitting || succeeded}
                  id="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : (
                    t('pay', {
                      amount: totalAmount,
                      currency,
                    })
                  )}
                </Button>

                <AppleGooglePay
                  clientSecret={clientSecret}
                  handleChange={handleChange}
                  setSucceeded={setSucceeded}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const CheckoutStripe = (props) => (
  // <Elements stripe={promise}>
  <StripeForm {...props} />
  // </Elements>
);

StripeForm.defaultProps = {
  formData: {},
};

StripeForm.propTypes = {
  openAlert: PropTypes.func.isRequired,
  clientSecret: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    currency: PropTypes.string,
  }),
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isAddFunds: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  clientSecret: checkout.selectors.selectSecret(state),
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStripe);
