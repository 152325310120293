import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      backgroundColor: '#000',
      color: '#fff',
      textAlign: 'center',
      '&.success': {
        backgroundColor: theme.palette.success.main,
      },
    },
    root: {
      padding: 10,
      width: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: '80%',
      maxHeight: 435,
    },
  })
);

function ConfirmationDialog({
  open,
  setOpen,
  actionOk,
  title,
  content,
  children,
  isConfirmation = true,
}) {
  const classes = useStyles();
  const handleCancel = async () => {
    if (!isConfirmation) {
      await actionOk();
    }
    setOpen(false);
  };

  const handleOk = async () => {
    await actionOk();
    setOpen(false);
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogTitle
        className={`${classes.title} ${!isConfirmation ? 'success' : ''}`}
      >
        {title}
      </DialogTitle>

      <DialogContent dividers>
        {children || (
          <Typography style={{ color: '#000', whiteSpace: 'break-spaces' }}>
            {content}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {isConfirmation ? (
          <>
            <Button autoFocus onClick={handleCancel} color="primary">
              No
            </Button>
            <Button onClick={handleOk} color="primary">
              Yes
            </Button>
          </>
        ) : (
          <Button
            autoFocus
            onClick={handleOk}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.string,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  actionOk: PropTypes.func.isRequired,
  isConfirmation: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  isConfirmation: true,
  children: null,
};

export default ConfirmationDialog;
