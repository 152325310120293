import { getOr, omit } from 'lodash/fp';
import i18n from 'i18next';

const handleSquareSubmit = async (values, getTotals, setFormData) => {
  const { amount, id, giftName, deliverAt } = values;
  const result = await getTotals({ amount, id });

  const {
    totalAmount,
    giveBackAmount,
    customerPaysAmount,
    feesHelperText,
    fees,
  } = getOr(0, 'payload', result);

  setFormData({
    ...values,
    amount: Number(amount),
    giftAmount: Number(amount),
    cardAmount: Number(amount).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
    transactionFee: Number(totalAmount - amount).toFixed(2),
    giveBackAmount: Number(giveBackAmount).toFixed(2),
    customerPaysAmount: Number(customerPaysAmount).toFixed(2),
    fees: Number(fees).toFixed(2),
    feesHelperText,
    giftName:
      giftName ||
      (values.giftFirstName
        ? `${values.giftFirstName} ${values.giftLastName}`
        : ''),
    culture: i18n.language,
    deliverAt,
  });
};

const handleStripeSubmit = async (values, getSecret, setFormData) => {
  const { amount, organizationName } = values;
  const payload = {
    ...omit(['organizationName'], values),
    amount: Number(amount),
    giftAmount: Number(amount),
  };

  const result = await getSecret(payload);
  const hasSecret = getOr('', ['payload', 'secret'], result);
  const {
    totalAmount,
    giveBackAmount,
    customerPaysAmount,
    fees,
    feesHelperText,
  } = getOr('', 'payload', result);
  if (hasSecret) {
    setFormData({
      ...payload,
      organizationName,
      cardAmount: Number(amount).toFixed(2),
      totalAmount: Number(totalAmount).toFixed(2),
      transactionFee: Number(totalAmount - amount).toFixed(2),
      giveBackAmount: Number(giveBackAmount).toFixed(2),
      customerPaysAmount: Number(customerPaysAmount).toFixed(2),
      fees: Number(fees).toFixed(2),
      feesHelperText,
      culture: i18n.language,
    });
  }
};

const handleCloverSubmit = async (values, getTotals, setFormData) => {
  const { amount, id, giftName, deliverAt } = values;
  const result = await getTotals({ amount, id });

  const {
    totalAmount,
    giveBackAmount,
    customerPaysAmount,
    fees,
    feesHelperText,
  } = getOr(0, 'payload', result);
  setFormData({
    ...values,
    amount: Number(amount),
    giftAmount: Number(amount),
    cardAmount: Number(amount).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
    transactionFee: Number(totalAmount - amount).toFixed(2),
    giveBackAmount: Number(giveBackAmount).toFixed(2),
    customerPaysAmount: Number(customerPaysAmount).toFixed(2),
    fees: Number(fees).toFixed(2),
    feesHelperText,
    giftName:
      giftName ||
      (values.giftFirstName
        ? `${values.giftFirstName} ${values.giftLastName}`
        : ''),
    culture: i18n.language,
    deliverAt,
  });
};

export { handleSquareSubmit, handleStripeSubmit, handleCloverSubmit };
