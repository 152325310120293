import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  Button,
  TextField,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';

const OtpDialog = (props) => {
  const { close, open, onSubmit } = props;
  const [otp, setOtp] = useState();

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      {/* <DialogTitle> */}
      {/*  An OTP has been send to your email, please enter to verify the owner of */}
      {/*  gift card */}
      {/* </DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          An OTP has been send to your email, please enter to verify the owner
          of gift card
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Otp"
          type="number"
          name="otp"
          fullWidth
          value={otp}
          onInput={(e) => setOtp(e.target.value)}
        />
        <DialogActions>
          <Button form="otpForm" onClick={() => onSubmit(otp)}>
            Send
          </Button>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
OtpDialog.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OtpDialog;
