import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { getOr, isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import TermsAndConditions from '../Checkout/TermsAndConditions';
import { checkout, organizations, alert, giftCards } from '../../../state';
import listOfStates from '../../../../misc/listOfStates.json';

const cloverUrl = process.env.GATSBY_CLOVER_SCRIPT;

const hasCloverScript = () =>
  document.querySelector('script#clover-sdk') !== null;

const hideCloverFooter = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  if (cloverFooter) {
    cloverFooter.style.display = 'none';
  }
};
const appendCloverScript = (setScriptLoaded) => {
  const script = document.createElement('script');
  script.id = 'clover-sdk';
  script.type = 'text/javascript';
  script.src = cloverUrl;
  script.onload = () => {
    setScriptLoaded(true);
  };
  // eslint-disable-next-line no-console
  script.onerror = () => console.error('Failed to Load Clover script!');
  document.body.appendChild(script);
};
const unmountClover = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  const cloverScript = document.querySelector('script#clover-sdk');
  const allCloverFrames = document.querySelectorAll(
    'iframe[title^="INTERMEDIATE"]'
  );
  if (cloverFooter) {
    cloverFooter.remove();
  }
  if (cloverScript) {
    cloverScript.remove();
  }
  if (!isEmpty(allCloverFrames)) {
    Array.from(allCloverFrames).forEach((frame) => frame.parentNode.remove());
  }
};

const unmountFields = () => {
  const allFrames = document.querySelectorAll('#clover-form iframe');
  if (!isEmpty(allFrames)) {
    Array.from(allFrames).forEach((frame) =>
      frame.parentNode.removeChild(frame)
    );
  }
};

let clover;

const demoKey = '4ca113d6-b9f3-4d4d-89cf-72fb03890d8b';

const CheckoutCloverDemo = ({
  getCloverIframeToken,
  openAlert,
  demoAmount,
  setDemoAmount,
  merchantId,
  redeemBalanceAmount,
  redeemCardBalance,
  giftCardNumber,
}) => {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [cloverIsReady, setCloverIsReady] = React.useState(false);

  const [errors, setErrors] = React.useState({
    CARD_NUMBER: '',
    CARD_DATE: '',
    CARD_CVV: '',
    CARD_POSTAL_CODE: '',
  });

  const cardNumberListner = (payload) => {
    const error = getOr('', ['CARD_NUMBER', 'error'], payload);
    if (errors) {
      setErrors({ ...errors, CARD_NUMBER: error });
    }
  };
  const cardDateListner = (event) => {
    const error = getOr('', ['CARD_DATE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_DATE: error });
    }
  };
  const cardCvvListner = (event) => {
    const error = getOr('', ['CARD_CVV', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_CVV: error });
    }
  };
  const cardPostalCodeListner = (event) => {
    const error = getOr('', ['CARD_POSTAL_CODE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_POSTAL_CODE: error });
    }
  };
  React.useEffect(() => {
    if (!hasCloverScript()) {
      appendCloverScript(setScriptLoaded);
    }
    return () => {
      if (hasCloverScript()) {
        unmountClover();
        setScriptLoaded(false);
        setCloverIsReady(false);
      }
    };
  }, []);
  React.useEffect(() => {
    if (scriptLoaded) {
      // TODO: isLoading State
      const createCloverInstance = async () => {
        const res = await getCloverIframeToken(demoKey);
        if (res.payload?.token) {
          const CloverInstance = window.Clover;
          clover = new CloverInstance(res.payload?.token);
          setCloverIsReady(true);
        } else {
          openAlert({
            message:
              'We cannot process payments at this time. Please try later.',
            severity: 'error',
          });
        }
      };
      createCloverInstance();
    }
  }, [scriptLoaded]);

  React.useEffect(() => {
    if (cloverIsReady) {
      const elements = clover.elements();
      // Create
      const cardNumber = elements.create('CARD_NUMBER');
      const cardDate = elements.create('CARD_DATE');
      const cardCvv = elements.create('CARD_CVV');
      const cardPostalCode = elements.create('CARD_POSTAL_CODE');
      // Mount
      cardNumber.mount('#card-number');
      cardDate.mount('#card-date');
      cardCvv.mount('#card-cvv');
      cardPostalCode.mount('#postal-code');
      // Add event listener
      cardNumber.addEventListener('blur', cardNumberListner);
      cardDate.addEventListener('blur', cardDateListner);
      cardCvv.addEventListener('blur', cardCvvListner);
      cardPostalCode.addEventListener('blur', cardPostalCodeListner);
      hideCloverFooter();
      return () => {
        cardNumber.removeEventListener('blur', cardNumberListner);
        cardDate.removeEventListener('blur', cardDateListner);
        cardCvv.removeEventListener('blur', cardCvvListner);
        cardPostalCode.removeEventListener('blur', cardPostalCodeListner);
        unmountFields();
      };
    }
    return undefined;
  }, [cloverIsReady]);

  if (!cloverIsReady) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Formik
      initialValues={{
        city: '',
        line1: '',
        state: '',
        postal_code: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const payload = {
          cardNumber: giftCardNumber,
          data: {
            amount: redeemBalanceAmount,
            merchantId,
          },
        };
        const res = await redeemCardBalance(payload);
        // eslint-disable-next-line no-console
        console.log(res);
        if (res.payload) {
          setDemoAmount(0);
          openAlert({
            message: 'Thank you for your purchase',
            severity: 'success',
          });
        } else {
          openAlert({
            message: 'Something went wrong. Please try again later.',
            severity: 'error',
          });
        }
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <Form id="clover-form" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                {/* Enable if Clover Supports Address Submit */}
                <Box pb={2}>
                  <Field
                    component={FormikTextField}
                    label="Address"
                    name="line1"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={FormikTextField}
                        label="City"
                        name="city"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label="State"
                        select
                        name="state"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            onClick={() => setFieldValue('state', state)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label="Zip code"
                        name="postal_code"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            maxLength: '5',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={2}>
                  <Grid container item xs={12}>
                    <TextField
                      id="card-number"
                      label="Credit Card Number"
                      name="ccnumber"
                      variant="outlined"
                      error={!!errors.CARD_NUMBER}
                      helperText={errors.CARD_NUMBER}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: 'div',
                      }}
                    />
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <TextField
                        id="card-date"
                        label="Expiration Date"
                        name="ccexp"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_DATE}
                        helperText={errors.CARD_DATE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="card-cvv"
                        label="CVC"
                        name="cvc"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_CVV}
                        helperText={errors.CARD_CVV}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="postal-code"
                        label="Postal Code"
                        name="postalCode"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_POSTAL_CODE}
                        helperText={errors.CARD_POSTAL_CODE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TermsAndConditions />
                </Box>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  id="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : (
                    t('pay', { amount: demoAmount, currency: 'USD' })
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

CheckoutCloverDemo.propTypes = {
  openAlert: PropTypes.func.isRequired,
  getCloverIframeToken: PropTypes.func.isRequired,
  setDemoAmount: PropTypes.func.isRequired,
  demoAmount: PropTypes.number.isRequired,
  redeemBalanceAmount: PropTypes.number.isRequired,
  merchantId: PropTypes.string.isRequired,
  redeemCardBalance: PropTypes.func.isRequired,
  giftCardNumber: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCloverIframeToken: (payload) =>
    dispatch(checkout.actions.getCloverIframeToken(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  redeemCardBalance: (payload) => dispatch(giftCards.actions.redeem(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCloverDemo);
