/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Link,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ShowMoreText from 'react-show-more-text';
import { makeStyles } from '@material-ui/core/styles';

import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, getOr } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { navigate as navigateGatsby } from 'gatsby';

import i18n from 'i18next';
import Slider from 'react-slick';
import { businesses, organizations, checkout } from '../../../state';
import UserInfoForm from './UserInfoForm';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import nextIcon from '../../../images/scroll-right.png';
import prevIcon from '../../../images/scroll-left.png';

const CardInfoSection = ({ organization, isLoading, textcolor }) => {
  const business = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles((theme) => ({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      // zIndex: -1,
    },
    checkBalance: {
      position: 'absolute',
      right: '5.7%',
      top: '50%',
      width: '250px',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      transform: 'translateY(-50%)',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        top: 'auto',
        right: 0,
        fontSize: '13px',
      },
    },
    checkBalanceWrapper: {
      position: 'absolute',
      width: '100%',
      top: '65px',
      left: 0,
      height: '170px',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        top: 'auto',
        height: 'auto',
        position: 'relative',
        paddingTop: '25px',
      },
    },
    slickThumb: {
      '& img': {
        border: '1px solid #fff',
        boxShadow: '0 0 3px rgba(0,0,0,0.5)',
        borderRadius: 5,
      },
    },
    slickImg: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [terms, setTerms] = React.useState('');
  const [listImages, setListImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(null);
  const updateBusiness = (payload) =>
    dispatch(businesses.actions.updateBusiness(payload));
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));
  const formData = useSelector(checkout.selectors.selectFormData);

  const sliderRef = useRef(null);

  const { businessId, id, termAndConditions, occasions } = business;
  const isBhnPartner = (getOr([], 'partner', business) || []).includes('BHN');

  const handleOpen = () => {
    setOpen(true);
  };

  const getDescription = (culture) => {
    if (!termAndConditions || !Array.isArray(termAndConditions)) return '';
    return termAndConditions.find((te) => te.culture === culture)?.description;
  };

  const getTerm = (culture) => {
    if (!termAndConditions || !Array.isArray(termAndConditions)) return '';
    return termAndConditions.find((te) => te.culture === culture)?.term;
  };

  useEffect(() => {
    setDescription(getDescription(i18n.language));
    setTerms(getTerm(i18n.language));
  }, [i18n.language, termAndConditions]);

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setExpand(!expand);
  };

  const handleCheckBalance = () => {
    navigateGatsby(`/check-balance/${id || businessId}`);
  };

  useEffect(() => {
    if (occasions) {
      const list = occasions.flatMap((o) =>
        o.images.filter((i) => i.status === 0)
      );
      setListImages(list);
    }
  }, [business]);

  const updateData = async (image) => {
    if (!image?.id) return;
    const updateFormData = {
      ...formData,
      giftCardImageId: image.id,
      imageUrl: image.url,
      occasionId: image.occasionId,
    };
    await updateBusiness(updateFormData);
    await setFormData(updateFormData);
  };

  useEffect(() => {
    const imageSelected = listImages[currentSlide];
    updateData(imageSelected);
  }, [currentSlide]);

  useEffect(() => {
    if (listImages.length > 0 && currentSlide === null) {
      setCurrentSlide(0);
      updateData(listImages[0]);
    }
  }, [listImages, currentSlide]);

  const scrollToActiveDot = (index) => {
    const dots = document.querySelector('.slick-dots');
    const activeDot = dots?.children[index];
    if (activeDot) {
      activeDot.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  const settings = {
    customPaging(i) {
      return (
        <a className={classes.slickThumb}>
          <img
            width={32}
            height={32}
            className={classes.slickImg}
            src={listImages[i].url}
            alt="Thumbnail slider"
          />
        </a>
      );
    },
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    waitForAnimate: false,
    afterChange(index) {
      setCurrentSlide(index);
      scrollToActiveDot(index);
    },
    nextArrow: (
      <Box style={{ backgroundColor: 'transparent', position: 'absolute' }}>
        <img src={nextIcon} alt="next" style={{ height: '40px' }} />
      </Box>
    ),
    prevArrow: (
      <Box style={{ backgroundColor: 'transparent', position: 'absolute' }}>
        <img src={prevIcon} alt="prev" style={{ height: '40px' }} />
      </Box>
    ),
  };

  return (
    <>
      <Box className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ zIndex: 2 }}>
            <Box pt={2} display="block" align="center">
              {business.displayGiftCardName && (
                <Typography variant="h5" color="primary">
                  {isLoading ? <Skeleton width="190px" /> : business.name}
                </Typography>
              )}
              {!!business.giveBack && (
                <Typography variant="subtitle1" color="primary">
                  {isLoading ? (
                    <Skeleton width="250px" />
                  ) : (
                    <>
                      {`${parseFloat((business.giveBack * 100).toFixed(2))}%`}{' '}
                      {t('willGoTo')}{' '}
                      {isEmpty(organization)
                        ? t('yourChosenNonprofit')
                        : organization.name}
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box className="slider-container" width={360} height={240}>
                <Slider
                  {...settings}
                  dots={listImages.length > 1}
                  ref={sliderRef}
                  nextArrow={listImages.length > 1 ? settings.nextArrow : null}
                  prevArrow={listImages.length > 1 ? settings.prevArrow : null}
                >
                  {listImages.map((image) => (
                    <Box width="100%" key={image}>
                      <img
                        width="100%"
                        height="100%"
                        src={image.url}
                        alt="Gift Card Images"
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                display="block"
                align="center"
                variant="body2"
                component="Box"
              >
                {isLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <ShowMoreText
                    lines={2}
                    more={t('showMore')}
                    less={t('showLess')}
                    onClick={onClick}
                    expanded={false}
                  >
                    {description}
                  </ShowMoreText>
                )}
              </Typography>
            </Grid>

            {isLoading ? (
              <Skeleton width="140px" />
            ) : (
              !isEmpty(terms) && (
                <Grid item xs={12}>
                  <Box pt={1}>
                    <Typography display="block" align="center" variant="body2">
                      <Link
                        component="button"
                        underline="hover"
                        style={{ color: 'blue' }}
                        onClick={handleOpen}
                      >
                        {t('termsAndConditions')}
                      </Link>
                    </Typography>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      scroll="paper"
                      aria-labelledby="terms and conditions"
                      aria-describedby="terms and conditions"
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <DialogTitle>{t('termsAndConditions')}</DialogTitle>
                      <DialogContent Boxiders>
                        <DialogContentText tabIndex={-1} component="Box">
                          <Typography variant="body2" component="Box">
                            {terms}
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          style={{ color: `${textcolor}` }}
                        >
                          {t('iAgree')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isBhnPartner && (
        <Box className={classes.checkBalanceWrapper}>
          <Link
            className={classes.checkBalance}
            underline="hover"
            onClick={handleCheckBalance}
          >
            {t('checkBalanceAddFunds')}
          </Link>
        </Box>
      )}
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <UserInfoForm />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CardInfoSection.defaultProps = {
  business: {},
  organization: {},
  textcolor: '#e4f9fc',
};

CardInfoSection.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    giveBack: PropTypes.number,
    imageUrl: PropTypes.string,
    terms: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
  }),
  organization: PropTypes.shape({
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  textcolor: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: businesses.selectors.selectIsLoading(state),
  organization: organizations.selectors.selectOrganization(state),
});

export default connect(mapStateToProps)(CardInfoSection);
