import * as Yup from 'yup';
import i18n from 'i18next';

const CheckBalanceSchema = () =>
  Yup.object().shape({
    cardNumber: Yup.string()
      .min(16, i18n.t('tooShort'))
      .max(20, i18n.t('tooLong'))
      .required(i18n.t('required')),
  });

export default CheckBalanceSchema;
