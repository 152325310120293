import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import CheckoutStripe from './CheckoutStripe';
import CheckoutSquare from './CheckoutSquare';
import CheckoutClover from './CheckoutClover';

import OrderDetails from './OrderDetails';

import PoweredByStripe from '../../../images/poweredByStripe.png';
import PoweredBySquare from '../../../images/poweredBySquare.svg';
import PoweredByClover from '../../../images/poweredByClover.png';

import { Header, Content, BusinessHeader, BusinessFooter } from '../../layouts';
import { businesses, checkout, checkCardBalance } from '../../../state';
import SEO from '../../seo';
import ChargeBreakdown from './ChargeBreakdown';
import TermsAndConditions from './TermsAndConditions';

const Checkout = ({
  business,
  clientSecret,
  location,
  cardDetails,
  ...nav
}) => {
  const useStyles = makeStyles(() => ({
    squareLogo: {
      height: '20px',
    },
    stripeLogo: {
      maxHeight: '30px',
    },
    cloverLogo: {
      maxHeight: '20px',
    },
    item1: {
      // order: 1,
      // [theme.breakpoints.up('sm')]: {
      //   order: 2,
      // },
    },
    item2: {
      // order: 2,
      // [theme.breakpoints.up('sm')]: {
      //   order: 1,
      // },
    },
    textColor: {
      '& *:not(.Mui-error):not(button)': {
        color: business.brandColors?.textColor,
      },
    },
  }));

  const { t } = useTranslation();
  const { id: routeId } = nav;
  const { org: orgId } = queryString.parse(location.search);
  const { name, partner, isWhitelabel, website, logoUrl } = business;
  const isSquareCheckout = partner === 'Square' || partner === 'BHN';
  const isCloverCheckout = partner === 'Clover';
  const isStripeCheckout = clientSecret && partner === 'Stripe';
  const isAllowedToCheckout =
    isCloverCheckout || isSquareCheckout || isStripeCheckout;
  if (!isAllowedToCheckout) {
    if (orgId) {
      navigate(`/card/${routeId}?org=${orgId}`, { replace: true });
      return null;
      // return <Redirect to={`/card/${routeId}?org=${orgId}`} noThrow />;
    }
    navigate(`/card/${routeId}`, { replace: true });
    return null;
    // return <Redirect to={`/card/${routeId}`} noThrow />;
  }
  const classes = useStyles();
  const merchantPintunaUrl = !orgId
    ? `/card/${routeId}`
    : `/card/${routeId}?org=${orgId}`;

  const isAddFunds = Boolean(cardDetails.cardNumber);

  return (
    <Box className={classes.textColor}>
      <SEO title="Checkout" />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={business.brandColors?.headerColor}
        />
      ) : (
        <Header logo bgcolor={business.brandColors?.headerColor} />
      )}
      <Divider />
      <Box bgcolor={business.brandColors?.formColor}>
        <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
          <Box pb={4}>
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box my={3}>
                <Typography display="block" align="center" variant="h5">
                  {name}
                  {isAddFunds && (
                    <>
                      : <b>{t('addFunds')}</b>
                    </>
                  )}
                </Typography>
                <Typography display="block" align="center" variant="subtitle1">
                  {t('confirmOrderDetail')}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={6}
            >
              <Grid item xs={12} sm={5} className={classes.item2}>
                <OrderDetails isAddFunds={isAddFunds} />
              </Grid>
              <Grid item xs={12} sm={7} className={classes.item1}>
                <Box mb={3}>
                  <ChargeBreakdown isAddFunds={isAddFunds} />
                </Box>

                {isSquareCheckout && (
                  <>
                    <CheckoutSquare isAddFunds={isAddFunds} routeId={routeId} />
                    <Box py={2} align="center">
                      <img
                        src={PoweredBySquare}
                        alt="Powered By Square"
                        className={classes.squareLogo}
                      />
                    </Box>
                  </>
                )}
                {isCloverCheckout && (
                  <>
                    <CheckoutClover isAddFunds={isAddFunds} routeId={routeId} />
                    <Box py={2} align="center">
                      <img
                        src={PoweredByClover}
                        alt="Powered By Clover"
                        className={classes.cloverLogo}
                      />
                    </Box>
                  </>
                )}
                {isStripeCheckout && (
                  <>
                    <CheckoutStripe isAddFunds={isAddFunds} routeId={routeId} />
                    <Box py={2} align="center">
                      <img
                        src={PoweredByStripe}
                        alt="Powered By Stripe"
                        className={classes.stripeLogo}
                      />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Box mt={1}>
                  <TermsAndConditions />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Box>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </Box>
  );
};

Checkout.defaultProps = {
  business: {},
  cardDetails: {},
};

Checkout.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    partner: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    logoUrl: PropTypes.string,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }),
  clientSecret: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cardDetails: PropTypes.shape({
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    businessId: PropTypes.string,
    currency: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  clientSecret: checkout.selectors.selectSecret(state),
  business: businesses.selectors.selectBusiness(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

export default connect(mapStateToProps)(Checkout);
