import * as Yup from 'yup';
import i18n from 'i18next';

const LoyaltyRegisterSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    lastName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('required')),
    localPhone: Yup.string()
      .matches(/\d{8,}/, 'Phone number is not valid')
      .required(i18n.t('required')),
    countryCode: Yup.string().required(i18n.t('required')),
    birthMonth: Yup.string().required(i18n.t('required')),
  });
};

export default LoyaltyRegisterSchema;
