import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const InputFrame = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current?.focus,
  }));

  return (
    <Component
      onReady={(element) => {
        elementRef.current = element;
      }}
      {...props}
    />
  );
};

InputFrame.propTypes = {
  component: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired,
};
export default InputFrame;
