import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import { Button, Grid, Box, CircularProgress } from '@material-ui/core';

import { getOr } from 'lodash/fp';
import { giftCards, alert, businesses } from '../../../state';

const RedemptionField = ({
  openAlert,
  demoAmount,
  setDemoAmount,
  setMerchantId,
  getGiftCardBalance,
  setRedeemBalanceAmount,
  setGiftCardNumber,
  business,
}) => {
  const [balance, setBalance] = React.useState(0);
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        cardNumber: '',
      }}
      onSubmit={async (values) => {
        const normalizedCardNumber = values.cardNumber.split(' ').join('');
        setGiftCardNumber(normalizedCardNumber);
        const res = await getGiftCardBalance(normalizedCardNumber);
        if (res.payload) {
          if (business && res.payload?.businessId !== business.id) {
            openAlert({
              message: 'Incorrect gift card number. Please try again.',
              severity: 'error',
            });
            return;
          }
          const cardBalance = getOr(0, ['payload', 'balance'], res);
          const merchantId = getOr(0, ['payload', 'merchantId'], res);
          if (cardBalance === 0) {
            openAlert({
              message: 'Your gift card balance is $0.00',
              severity: 'error',
            });
            return;
          }
          setBalance(cardBalance);
          const calcTotal = () => {
            const totalLeft = demoAmount - cardBalance;
            if (demoAmount < cardBalance) {
              const cardAmountApplied =
                cardBalance - (cardBalance - demoAmount);
              setRedeemBalanceAmount(cardAmountApplied);
              return 0;
            }
            // eslint-disable-next-line no-console
            console.log('cardBalance', cardBalance);
            setRedeemBalanceAmount(cardBalance);
            return totalLeft;
          };
          setDemoAmount(calcTotal());
          setMerchantId(merchantId);
          // openAlert({
          //   message: 'Gift card successfully applied',
          //   severity: 'success',
          // });
        } else {
          openAlert({
            message: 'Incorrect gift card number. Please try again.',
            severity: 'error',
          });
        }
        // eslint-disable-next-line no-console
        console.log(res);
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form id="redemption-form" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Box pb={1}>
                  <Field
                    component={FormikTextField}
                    label={t('giftCardNumber')}
                    name="cardNumber"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting || balance !== 0}
                  fullWidth
                  // size="small"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : (
                    t('applyGiftCard')
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

RedemptionField.propTypes = {
  openAlert: PropTypes.func.isRequired,
  setDemoAmount: PropTypes.func.isRequired,
  getGiftCardBalance: PropTypes.func.isRequired,
  setRedeemBalanceAmount: PropTypes.func.isRequired,
  setMerchantId: PropTypes.func.isRequired,
  demoAmount: PropTypes.number.isRequired,
  setGiftCardNumber: PropTypes.func.isRequired,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amounts: PropTypes.arrayOf(PropTypes.number),
    maxAmount: PropTypes.number,
    minAmount: PropTypes.number,
    allowCustomAmount: PropTypes.bool,
    imageUrl: PropTypes.string,
    partner: PropTypes.string,
    minFeeCoverAmount: PropTypes.number,
    currency: PropTypes.string,
    country: PropTypes.string,
    giveBack: PropTypes.number,
    customAmountDiscount: PropTypes.number,
    discounts: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  getGiftCardBalance: (payload) => dispatch(giftCards.actions.balance(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionField);
