import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { Box, Grid, Divider } from '@material-ui/core';
import queryString from 'query-string';
import { navigate as gatsbyNavigate } from 'gatsby';

import { businesses, organizations, checkCardBalance } from '../../../state';

import CardInfoSection from './CardInfoSection';
import UserInfoForm from './UserInfoForm';
import SEO from '../../seo';
import { Header, Content, BusinessHeader, BusinessFooter } from '../../layouts';

const CardAddFunds = ({
  fetchBusiness,
  fetchOrganization,
  business,
  location,
  fetchOrganizations,
  cardDetails,
  ...nav
}) => {
  const { id: routeId } = nav;
  const { org: orgId } = queryString.parse(location.search);
  React.useEffect(() => {
    if (!cardDetails?.cardNumber) {
      gatsbyNavigate(`/check-balance/${routeId}`);
    }
    (async () => {
      await fetchBusiness(routeId).then(({ error }) => {
        // TODO create dropdown notification
        if (!isEmpty(error)) {
          gatsbyNavigate(`/check-balance`);
        }
      });
      await fetchOrganizations();
      if (orgId) {
        await fetchOrganization(orgId);
      }
    })();
  }, []);

  const { name, isWhitelabel, logoUrl, website } = business;

  const merchantPintunaUrl = !orgId
    ? `/add-funds/${routeId}`
    : `/add-funds/${routeId}?org=${orgId}`;
  return (
    <>
      <SEO title={`${name}`} />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={business.brandColors?.headerColor}
        />
      ) : (
        <Header logo bgcolor={business.brandColors?.headerColor} />
      )}
      <Divider />
      <Content
        style={{ minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)' }}
      >
        <CardInfoSection bgcolor={business.brandColors?.backgroundColor} />
        <Box pb={2} bgcolor={business.brandColors?.formColor}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              <UserInfoForm path="/" />
            </Grid>
          </Grid>
        </Box>
      </Content>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </>
  );
};

CardAddFunds.defaultProps = {
  cardDetails: {
    cardNumber: '',
  },
};

CardAddFunds.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  cardDetails: PropTypes.shape({
    cardNumber: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  cardDetails: checkCardBalance.selectors.selectCardDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardAddFunds);
