import React from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { getOr } from 'lodash/fp';
import { useLocation, useNavigate } from '@reach/router';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { TextField } from 'formik-material-ui';
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Typography,
  MenuItem,
} from '@material-ui/core';

import CustomInputField from '../../custom/CustomInputField';
import OnBoardSchema from './validation';
import { businessLead } from '../../../state';
import businessCategories from '../../../../misc/businessCategoryList';

const OnBoardBusinessForm = ({ submitBusiness, handleSuccess }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { org } = queryString.parse(getOr('', 'search', location));
  const handleBack = () => (org ? navigate(`/?org=${org}`) : navigate(`/`));

  return (
    <Formik
      enableReinitialize
      validationSchema={OnBoardSchema}
      initialValues={{
        businessName: '',
        businessType: '',
        businessWeb: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        firstName: '',
        lastName: '',
        email: '',
      }}
      onSubmit={async (values, actions) => {
        try {
          const resp = await submitBusiness(values);
          actions.setSubmitting(false);

          handleSuccess();
          throw resp;
        } catch (e) {
          actions.setSubmitting(false);
          // TODO: return alert snackbar
        }
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <Box pt={2} pb={1}>
              <Typography variant="body2">Business Details</Typography>
            </Box>
            <Box pb={2}>
              <Field
                component={TextField}
                name="businessName"
                label="Business name"
                variant="outlined"
                fullWidth
                required
              />
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    required
                    select
                    label="Business type"
                    name="businessType"
                    variant="outlined"
                  >
                    {businessCategories.map((category) => (
                      <MenuItem
                        key={category}
                        onClick={() => setFieldValue('businessType', category)}
                        value={category}
                      >
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label="Business website"
                    name="businessWeb"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={1}>
              <Typography variant="body2">Business Contact</Typography>
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label="First name"
                    name="contactFirstName"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label="Last name"
                    name="contactLastName"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    required
                    label="E-mail"
                    name="contactEmail"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    name="contactPhone"
                    label="Phone"
                    variant="outlined"
                    maskType="phone"
                    fullWidth
                    extraEndAdornments={null}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={1}>
              <Typography variant="body2">Your Information</Typography>
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label="First name"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label="Last name"
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                label="E-mail"
                name="email"
                variant="outlined"
              />
              <Box pl={1}>
                <Typography variant="caption" color="textSecondary">
                  *We will send you an email to confirm the recommendation.
                </Typography>
              </Box>
            </Box>
            <Box pb={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    size="large"
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress color="secondary" size={24} />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

OnBoardBusinessForm.propTypes = {
  submitBusiness: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  submitBusiness: (payload) =>
    dispatch(businessLead.actions.submitBusiness(payload)),
});

export default connect(null, mapDispatchToProps)(OnBoardBusinessForm);
