import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@material-ui/core';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, organizations } from '../../../state';

import CardInfoSection from './CardInfoSection';
import SEO from '../../seo';
import { Header, Content, BusinessHeader, BusinessFooter } from '../../layouts';

const CardDetails = ({
  fetchBusiness,
  fetchOrganization,
  location,
  fetchOrganizations,
  ...nav
}) => {
  const business = useSelector(businesses.selectors.selectBusiness);
  const dispatch = useDispatch();
  const getBusiness = (payload) =>
    dispatch(businesses.actions.fetchBusiness(payload));
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(button):not(button span):not(.Mui-selected span *)': {
        color: business.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { id: routeId } = nav;
  const { org: orgId } = queryString.parse(location.search);
  React.useEffect(() => {
    (async () => {
      await getBusiness(routeId);
      await fetchOrganizations();
      if (orgId) {
        await fetchOrganization(orgId);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { name, isWhitelabel, logoUrl, website } = business;
  const merchantPintunaUrl = !orgId
    ? `/card/${routeId}`
    : `/card/${routeId}?org=${orgId}`;
  return (
    <Box className={classes.textColor}>
      <SEO title={`${name}`} />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={business.brandColors?.headerColor}
        />
      ) : (
        <Header logo bgcolor={business.brandColors?.headerColor} />
      )}
      <Divider />
      <Box bgcolor={business.brandColors?.formColor}>
        <Content
          style={{
            minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)',
          }}
        >
          <CardInfoSection bgcolor={business.brandColors?.backgroundColor} />
        </Content>
      </Box>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </Box>
  );
};

CardDetails.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
