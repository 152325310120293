/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { memberships } from '../../../state';
import { TransactionTable } from '../../custom';
import AddPerksForm from './AddFreeItemForm';

const FreeItemTransaction = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const membership = useSelector(memberships.selectors.selectDetails);
  useEffect(() => {
    if (membership?.id) {
      dispatch(memberships.actions.getMembershipFreeItem(membership.id));
    }
  }, [membership]);

  const colsTitles = [
    t('membership.freeItemRegister.dateTime'),
    t('membership.freeItemRegister.freeItemPerks'),
    t('membership.freeItemRegister.quantity'),
    t('membership.freeItemRegister.notes'),
  ];

  const rowKeys = ['createdAt', 'name', 'quantity', 'note'];

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const freeItemConfigs = useSelector(memberships.selectors.selectFreeItems);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD, YYYY HH:mm:ss'),
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => handleOpenModal()}
        variant="contained"
        color="primary"
      >
        {t('membership.freeItemRegister.addFreeItem')}
      </Button>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={freeItemConfigs}
          mods={mods}
          isLoading={isLoading}
          noDataMsg={t('noDataMsg')}
        />
      </Box>

      <AddPerksForm isOpen={isOpen} closeModal={handleCloseModal} />
    </>
  );
};

export default FreeItemTransaction;
