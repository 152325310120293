import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { navigate, useParams } from '@reach/router';

import i18n from 'i18next';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { debounce } from 'lodash';
import moment from 'moment';

import { handleIOSMsg } from '../../../utils';
import ConfirmationDialog from '../../custom/ConfirmationDialog';
import { alert, checkout, memberships, organizations } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
// import listOfStates from '../../../../misc/listOfStates.json';
import MembershipRegisterSchema from './MembershipRegisterSchema';
import './styles.css';
import SponsorImg from './SponsorImg';
import { countries } from '../../../utils/constants';
import api from '../../../state/memberships/api';
import MembershipFeeDetails from './MembershipFeeDetails';

const appId = process.env.GATSBY_APLICATION_ID;
const locationId = process.env.GATSBY_LOCATION_ID;
const sandbox = process.env.GATSBY_SANDBOX_SQUARE_PAYMENT_FORM;

const MembershipRegisterForm = ({ formData, setFormData, openAlert }) => {
  const { id, membershipId } = useParams();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const useStyles = makeStyles(() => ({
    paymentCard: {
      overflow: 'visible',
      position: 'relative',
      backgroundColor: membershipConfig.brandColors?.formColor || '#fff',
    },
    paymentHeader: {
      position: 'absolute',
      top: '-20px',
      left: '10px',
      zIndex: 1,
      padding: '0 5px',
      backgroundColor: membershipConfig.brandColors?.formColor || '#fff',
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const [isLoadingMembership, setIsLoadingMembership] = useState(false);
  const formikRef = useRef();
  const dispatch = useDispatch();

  const calculateServiceFee = (amount) => {
    if (membershipConfig?.id) {
      if (
        !membershipConfig.enableRecurringCost ||
        !membershipConfig.recurringCost
      ) {
        return 0;
      }
      if (membershipConfig.recurringCostType === 0) {
        return (amount * membershipConfig.recurringCost) / 100;
      }
      if (membershipConfig.recurringCostType === 1) {
        return membershipConfig.recurringCost;
      }
    }
    return 0;
  };
  const calculateOneTimeCost = () => {
    return 0;
  };

  const calculateTotalAmount = (amount) => {
    if (membershipConfig?.id) {
      if (membershipConfig.hasTrial && !membershipConfig.trialDays > 0) {
        return 0;
      }
      const extraFee =
        calculateServiceFee(amount) + calculateOneTimeCost(amount);
      return membershipConfig.useTax
        ? amount + (amount * membershipConfig.tax) / 100 + extraFee
        : amount + extraFee;
    }
    return amount;
  };
  const calculateMonthlyAmount = (amount) => {
    if (membershipConfig?.id) {
      if (membershipConfig.hasTrial && !membershipConfig.trialDays > 0) {
        return 0;
      }
      return (
        amount +
        calculateServiceFee(amount) +
        (membershipConfig.useTax ? (amount * membershipConfig.tax) / 100 : 0)
      );
    }
    return amount;
  };
  const [membership, setMembership] = useState({});
  const [plan, setPlan] = useState(null);

  const getMembership = async (_id) => {
    try {
      setIsLoadingMembership(true);
      if (!membershipId) return navigate(`/membership/${id}/register`);
      const membershipInfo = await api.getMembershipById(_id)();
      if (!membershipInfo) {
        return navigate(`/membership/${id}/register`);
      }
      if (membershipInfo) {
        setMembership(membershipInfo);
        setPlan(membershipInfo.plan);

        setFormData({
          ...formData,
          customerPaysAmount: calculateTotalAmount(
            membershipInfo.plan.amount
          ).toString(),
          recurringCost: calculateServiceFee(
            membershipInfo.plan.amount
          ).toString(),
          oneTimeCost: calculateOneTimeCost(
            membershipInfo.plan.amount
          ).toString(),
          totalAmount: calculateMonthlyAmount(
            membershipInfo.plan.amount
          ).toString(),
          currency: membershipInfo.plan.currency || 'USD',
          amount: membershipInfo.plan.amount,
        });
      }
      setIsLoadingMembership(false);
      return null;
    } catch (e) {
      console.log(e);
      return navigate(`/membership/${id}/register`);
    }
  };
  useEffect(() => {
    if (membershipConfig.plans?.[0]) {
      getMembership(membershipId);
    }
  }, [membershipConfig]);
  React.useEffect(() => {
    if (formData?.culture) {
      i18n.changeLanguage(formData.culture);
    }
  }, [formData]);

  const [, setIsSubmitting] = React.useState(false);
  const [, setWalletPayIsLoading] = React.useState(false);
  const [isConfirm, setIsConfirm] = React.useState(false);

  const cardNonceResponseReceived = async (details, buyer) => {
    setWalletPayIsLoading(true);

    if (details.status !== 'OK') {
      console.log(details);
      setIsSubmitting(false);
      setWalletPayIsLoading(false);
      return;
    }
    await setFormData(formikRef.current.values);

    const response = await dispatch(
      memberships.actions.updateMembershipCardOnFile({
        ...formikRef.current.values,
        nonce: details.token,
        verificationToken: buyer.token,
        expMonth: details.card?.exp_month,
        expYear: details.card?.exp_year,
      })
    );

    if (response.error) {
      handleIOSMsg('payment_failed');
      openAlert({
        message:
          response.payload?.message || t('membership.newCardCannotBeSaved'),
        severity: 'error',
      });
      setIsSubmitting(false);
      setWalletPayIsLoading(false);
    } else if (response.payload) {
      setIsConfirm(true);
    }
  };

  useEffect(() => {
    if (
      (!membershipConfig.allowSelectPlan &&
        membershipConfig.plans &&
        membershipConfig.plans[0]?.id) ||
      membershipConfig?.plans?.length === 1
    )
      setPlan(membershipConfig.plans[0]);

    if (membershipConfig?.country) {
      const country = countries.find(
        (c) => c.code === membershipConfig.country
      );
      if (country) {
        formikRef?.current?.setFieldValue('countryCode', country.code);
      }
    }
  }, [membershipConfig]);

  const createVerificationDetails = () => ({
    amount: formData.customerPaysAmount,
    currencyCode: formData.currency || 'USD',
    intent: 'STORE',
    billingContact: {
      familyName: formData.lastName,
      givenName: formData.firstName,
      email: formData.email,
    },
  });
  const validateMembershipCode = async (code, ctx) => {
    const res = await dispatch(
      memberships.actions.validateMembershipCode({
        code,
        businessId: membershipConfig.businessId,
      })
    );
    if (res.error) {
      return ctx.createError({
        message: res.payload?.message || 'Invalid membership #',
        path: 'code',
      });
    }
    return true;
  };
  const debouncedValidateMembershipCode = debounce(validateMembershipCode, 300);

  return (
    <>
      <Box
        borderRadius={20}
        border="1px solid #ccc"
        p={5}
        mb={4}
        bgcolor={membershipConfig.brandColors?.formColor || '#fff'}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} justifyContent="center">
              <Box maxWidth={300} margin="auto">
                {membershipConfig.hasSponsor === true && (
                  <Box mt={1} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        textDecoration: 'underline grey',
                        color: 'grey',
                        textAlign: 'center',
                      }}
                    >
                      {membershipConfig.sponsorText}
                    </Typography>
                    <SponsorImg
                      imageUrl={membershipConfig.sponsorImage}
                      alt={membershipConfig.name}
                    />
                  </Box>
                )}
                <CustomImgCard
                  isLoading={isLoading}
                  name={membershipConfig.businessName}
                  imageUrl={plan?.imageUrl || membershipConfig.imageUrl}
                />
              </Box>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Box py={3}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h4" align="center">
                        Renew -{' '}
                        {plan
                          ? `${plan.name} for ${t('cardValue', {
                              amount: formData.totalAmount,
                              formatParams: {
                                amount: { currency: formData.currency },
                              },
                            })}/${plan.period?.toLowerCase()}`
                          : t('membership.signUpForm')}
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: 80 }}>
                      <MembershipFeeDetails
                        formData={formData}
                        membershipConfig={membershipConfig}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isLoading || isLoadingMembership ? (
                <Box>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <Formik
                  innerRef={formikRef}
                  validationSchema={() =>
                    MembershipRegisterSchema(
                      membershipConfig.showAddressInput,
                      debouncedValidateMembershipCode
                    )
                  }
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    address: '',
                    state: '',
                    city: '',
                    zipcode: '',
                    birthMonth: undefined,
                    period: '',
                    code: '',
                    ...membership,
                    expirationDate: membership.expirationDate
                      ? moment(membership.expirationDate).format('YYYY/MM/DD')
                      : '',
                    nextExpirationDate: membership.nextExpirationDate
                      ? moment(membership.nextExpirationDate).format(
                          'YYYY/MM/DD'
                        )
                      : '',
                    numberType: membershipConfig.numberType,
                    captureBirthMonth: membershipConfig.captureBirthMonth,
                    hasLoyalty: membershipConfig.hasLoyalty,
                    customQuestion: membershipConfig.customQuestion,
                    questions: membershipConfig.questions?.length
                      ? membershipConfig.questions.map((q) => ({
                          ...q,
                          note: '',
                        }))
                      : [{ note: '' }],
                    planId: membership.plan?.id,
                    amount: membership.plan?.amount,
                  }}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(true);
                    setFormData({
                      ...values,
                      code: values.code.toString(),
                    });
                    actions.setSubmitting(false);
                  }}
                >
                  {({ errors, values }) => {
                    return (
                      <Form margin="dense" size="small">
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="stretch"
                        >
                          <Grid item xs={12}>
                            <Box pb={2}>
                              {(membership?.id ||
                                membershipConfig.numberType === 2) && (
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  <Grid item xs={12} md={6}>
                                    <CustomInputField
                                      customInput={Field}
                                      component={FormikTextField}
                                      capitalize
                                      fullWidth
                                      required
                                      label={`${t('membership.membership')} #`}
                                      name="code"
                                      removeArrows
                                      variant="outlined"
                                      disabled={!!membership?.id}
                                    />
                                  </Grid>
                                  {values.nextExpirationDate && (
                                    <Grid item xs={12} md={6}>
                                      <CustomInputField
                                        customInput={Field}
                                        component={FormikTextField}
                                        capitalize
                                        fullWidth
                                        required
                                        label={t('expirationDate')}
                                        name="nextExpirationDate"
                                        type="string"
                                        removeArrows
                                        variant="outlined"
                                        disabled={!!membership?.id}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Box>
                            <Box pb={2}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    capitalize
                                    fullWidth
                                    required
                                    label={t('yourFirstName')}
                                    name="firstName"
                                    variant="outlined"
                                    disabled={!!membership?.id}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    capitalize
                                    fullWidth
                                    required
                                    label={t('yourLastName')}
                                    name="lastName"
                                    variant="outlined"
                                    disabled={!!membership?.id}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box pb={2}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    trim
                                    fullWidth
                                    required
                                    label={t('yourPhoneNumber')}
                                    name="phone"
                                    variant="outlined"
                                    disabled={!!membership?.id}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    trim
                                    fullWidth
                                    required
                                    label={t('yourEmail')}
                                    name="email"
                                    variant="outlined"
                                    disabled={!!membership?.id}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Box mb={2} mt={4}>
                              <Card
                                variant="outlined"
                                className={classes.paymentCard}
                              >
                                <CardHeader
                                  title={t('paymentDetails')}
                                  className={classes.paymentHeader}
                                />
                                <CardContent>
                                  <Box pt={1}>
                                    <PaymentForm
                                      sandbox={sandbox === 'true'}
                                      applicationId={appId}
                                      locationId={locationId}
                                      cardTokenizeResponseReceived={
                                        cardNonceResponseReceived
                                      }
                                      createVerificationDetails={
                                        createVerificationDetails
                                      }
                                    >
                                      <CreditCard
                                        buttonProps={{
                                          isLoading:
                                            isLoading ||
                                            Object.keys(errors).length > 0,
                                        }}
                                      >
                                        {moment(
                                          membership.expirationDate
                                        ).isBefore(moment())
                                          ? `Pay (Total = ${t('cardValue', {
                                              amount:
                                                formData.customerPaysAmount,
                                              formatParams: {
                                                amount: {
                                                  currency: formData.currency,
                                                },
                                              },
                                            })})`
                                          : t(
                                              'membership.saveCreditCardOnFile'
                                            )}
                                      </CreditCard>
                                    </PaymentForm>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box my={3}>
                          <Typography style={{ whiteSpace: 'pre-wrap' }}>
                            {membershipConfig.terms}
                          </Typography>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        isConfirmation={false}
        title="Success!"
        content={
          membership.partnerCardOnFileId
            ? t('membership.successChangeCard')
            : t('membership.successAddCard')
        }
        actionOk={() => {
          setTimeout(() => navigate(`/membership/${id}/register`), 100);
        }}
      />
    </>
  );
};

MembershipRegisterForm.propTypes = {
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    promoCode: PropTypes.string,
    isGift: PropTypes.bool,
    organizationId: PropTypes.string,
    businessId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    city: PropTypes.string,
    line1: PropTypes.string,
    postal_code: PropTypes.string,
    orgCode: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.string,
    country: PropTypes.string,
    code: PropTypes.string,
    culture: PropTypes.string,
    plan: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        note: PropTypes.string,
      })
    ),
    recurringCost: PropTypes.number,
    oneTimeCost: PropTypes.number,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFormData: (payload) => dispatch(checkout.actions.setFormData(payload)),
  squareCheckout: (payload) =>
    dispatch(checkout.actions.squareCheckout(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipRegisterForm);
