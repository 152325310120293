import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grow,
  IconButton,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Tune } from '@material-ui/icons';
import { businesses, types, categories } from '../../../state';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
    zIndex: 2,
  },
}));

const CardFilters = ({ typeId, orderBy, categoryId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = (e) => {
    const order = e.target.id;
    const payload = { typeId, categoryId, order };
    orderBy(payload);
    handleClose(e);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        aria-label="Filter By"
        aria-haspopup="true"
        aria-controls={open ? 'menu-list-grow' : undefined}
        onClick={handleToggle}
        ref={anchorRef}
        className={classes.margin}
        style={{ padding: '8px' }}
      >
        <Tune fontSize="small" />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.paper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="filter"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem id="giveback" onClick={handleClick}>
                    {t('byGiveback')}
                  </MenuItem>
                  <MenuItem id="name" onClick={handleClick}>
                    {t('byName')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

CardFilters.propTypes = {
  typeId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  orderBy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  typeId: types.selectors.selectSelectedType(state),
  categoryId: categories.selectors.selectSelectedCategory(state),
});

const mapDispatchToProps = (dispatch) => ({
  orderBy: (payload) => dispatch(businesses.actions.orderBy(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardFilters);
