import React from 'react';
import { useFormikContext, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { alert, memberships } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const UserInfoForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const { setFieldValue } = useFormikContext();
  const clearMembershipInfo = () => {
    if (setFieldValue) {
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldValue('email', '');
    }
  };

  const getMembership = async (code) => {
    if (!code.trim()) return;
    try {
      const res = await dispatch(memberships.actions.getMembershipByCode(code));
      if (res.error) {
        openAlert({
          message: res?.payload?.message || 'Membership is not found',
          severity: 'error',
        });
        clearMembershipInfo();
      } else if (res.payload) {
        const membershipInfo = res.payload;
        if (!membershipInfo) {
          clearMembershipInfo();
        } else if (setFieldValue) {
          setFieldValue('firstName', membershipInfo.firstName);
          setFieldValue('lastName', membershipInfo.lastName);
          setFieldValue('email', membershipInfo.email);
        }
      }
    } catch (e) {
      clearMembershipInfo();
    }
  };

  return (
    <Box style={{ border: '1px solid #ccc' }} p={4} mt={4}>
      <Box mb={3}>
        <Typography variant="h5" style={{ fontFamily: 'gilmer-bold' }}>
          {t('ticket.yourInformation')}
        </Typography>
      </Box>
      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomInputField
            InputProps={{
              style: {
                fontSize: '1.05rem',
              },
            }}
            customInput={Field}
            component={TextField}
            capitalize
            fullWidth
            label={t('ticket.memberIdOptional')}
            name="membershipCode"
            variant="outlined"
            onKeyDown={(e) => {
              if (e.keyCode === 13 || e.keyCode === 'Enter') {
                getMembership(e.target?.value);
              }
            }}
            onBlur={(e) => {
              getMembership(e.target?.value);
            }}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <CustomInputField
            InputProps={{
              style: {
                fontSize: '1.05rem',
              },
            }}
            customInput={Field}
            component={TextField}
            capitalize
            fullWidth
            required
            label={t('yourFirstName')}
            name="firstName"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInputField
            InputProps={{
              style: {
                fontSize: '1.05rem',
              },
            }}
            customInput={Field}
            component={TextField}
            capitalize
            fullWidth
            required
            label={t('yourLastName')}
            name="lastName"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            InputProps={{
              style: {
                fontSize: '1.05rem',
              },
            }}
            customInput={Field}
            component={TextField}
            trim
            fullWidth
            required
            label={t('yourEmail')}
            name="email"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfoForm;
