/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import { Skeleton } from '@material-ui/lab';
import moment from 'moment';

import { PhotoCamera } from '@material-ui/icons';
import { alert, memberships, organizations } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
import FreeItemRegisterSchema from './FreeItemRegisterSchema';
import FreeItemTransaction from './FreeItemTransaction';
import CheckInScanner from '../../custom/CheckInScanner';

const FreeItemRegisterForm = ({ routeId }) => {
  const { t } = useTranslation();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const isLoading = false;
  const [, setIsLoadingMembership] = useState(false);
  const formikRef = useRef();
  const [, setMemberId] = useState('');
  const [currentMemberId, setCurrentMemberId] = useState('');

  const clearMembershipInfo = () => {
    formikRef.current.setFieldValue('firstName', '');
    formikRef.current.setFieldValue('lastName', '');
    formikRef.current.setFieldValue('expirationDate', '');
    formikRef.current.setFieldValue('membershipPlan', '');
    setCurrentMemberId('');
  };
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  const getMembership = async (code) => {
    if (!code) return null;
    try {
      setIsLoadingMembership(true);
      const res = await dispatch(memberships.actions.getMembershipByCode(code));
      console.log(res);
      if (res.payload) {
        if (res.error) {
          openAlert({
            message: res?.payload?.message || 'Membership is not found',
            severity: 'error',
          });
        }
        const membershipInfo = res.payload;
        if (!membershipInfo) {
          clearMembershipInfo();
        } else {
          formikRef.current.setFieldValue(
            'firstName',
            membershipInfo.firstName
          );
          formikRef.current.setFieldValue('lastName', membershipInfo.lastName);
          formikRef.current.setFieldValue(
            'expirationDate',
            moment(membershipInfo.expirationDate).format('YYYY/MM/DD')
          );
          formikRef.current.setFieldValue(
            'membershipPlan',
            membershipInfo.plan.name
          );
          setCurrentMemberId(membershipInfo.id);
        }
      }
      setIsLoadingMembership(false);
      return null;
    } catch (e) {
      clearMembershipInfo();
      setIsLoadingMembership(false);
      return null;
    }
  };

  const [showScanner, setShowScanner] = useState(false);

  const setCode = (val) => {
    if (val) {
      setMemberId(val);
      getMembership(val);
      setShowScanner(false);
    }
  };

  return (
    <Box
      borderRadius={20}
      border="1px solid #ccc"
      p={5}
      mb={4}
      bgcolor={membershipConfig.brandColors?.formColor || '#fff'}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box width={270} alignItems="center" margin="auto">
                <CustomImgCard
                  isLoading={false}
                  name={membershipConfig.businessName}
                  imageUrl={membershipConfig.imageUrl}
                />
              </Box>
              <Box py={2}>
                <Typography variant="h4" align="center">
                  Enter Member Perks /Free Items
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Box>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Box>
            ) : (
              <Formik
                innerRef={formikRef}
                validationSchema={() => FreeItemRegisterSchema()}
                initialValues={{
                  memberId: '',
                }}
              >
                {({ values }) => {
                  return (
                    <Form margin="dense" size="small">
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid container item xs={8} md={4} spacing={1}>
                              <Grid item xs>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  fullWidth
                                  type="string"
                                  removeArrows
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={`${t('membership.number')}*`}
                                  name="code"
                                  variant="outlined"
                                  onKeyDown={(e) => {
                                    if (
                                      e.keyCode === 13 ||
                                      e.keyCode === 'Enter'
                                    ) {
                                      setMemberId(e.target?.value);
                                      getMembership(e.target?.value);
                                    }
                                  }}
                                  onBlur={() => {
                                    setMemberId(values.code);
                                    getMembership(values.code);
                                  }}
                                  autoComplete="off"
                                />
                              </Grid>
                              <Grid item textAlign="center">
                                <Button
                                  variant="outlined"
                                  onClick={() => setShowScanner(true)}
                                  style={{ padding: '10px 5px' }}
                                >
                                  <PhotoCamera />
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box pb={2}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  capitalize
                                  fullWidth
                                  required
                                  label={t(
                                    'membership.freeItemRegister.firstName'
                                  )}
                                  name="firstName"
                                  variant="outlined"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Box>
                              <CustomInputField
                                customInput={Field}
                                component={FormikTextField}
                                trim
                                fullWidth
                                required
                                label={t(
                                  'membership.freeItemRegister.expirationDate'
                                )}
                                name="expirationDate"
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box pb={2}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  capitalize
                                  fullWidth
                                  required
                                  label={t(
                                    'membership.freeItemRegister.lastName'
                                  )}
                                  name="lastName"
                                  variant="outlined"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Box>
                              <CustomInputField
                                customInput={Field}
                                component={FormikTextField}
                                trim
                                fullWidth
                                required
                                label={t(
                                  'membership.freeItemRegister.membershipPlan'
                                )}
                                name="membershipPlan"
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box pt={5}>
                        {currentMemberId && (
                          <FreeItemTransaction
                            membershipConfigId={routeId}
                            membershipId={currentMemberId}
                          />
                        )}
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CheckInScanner
        setCode={setCode}
        open={showScanner}
        close={() => setShowScanner(false)}
      />
    </Box>
  );
};

FreeItemRegisterForm.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  routeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreeItemRegisterForm);
