/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';

import { useTranslation } from 'react-i18next';
import { CustomImgCard } from '../../custom';
import {
  businesses,
  organizations,
  checkCardBalance,
  checkout,
} from '../../../state';

const CardInfoSection = ({
  business,
  organization,
  isLoading,
  showGiveBack,
}) => {
  const { name, imageUrl } = business;
  const cardDetails = useSelector(checkCardBalance.selectors.selectCardDetails);
  const useStyles = makeStyles({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
  });
  const classes = useStyles();

  const [imgUrl, setImgUrl] = useState(imageUrl);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setFormData = (payload) =>
    dispatch(checkout.actions.setFormData(payload));

  useEffect(() => {
    if (cardDetails.imageUrl) {
      const {
        giftCardImageId,
        giftCardTermId,
        occasionId,
        giftCardConfigId,
        languageId,
      } = cardDetails;
      setImgUrl(cardDetails.imageUrl);
      setFormData({
        imageUrl: cardDetails.imageUrl,
        giftCardImageId,
        giftCardTermId,
        occasionId,
        giftCardConfigId,
        languageId,
      });
    }
  }, [cardDetails]);

  useEffect(() => {
    if (business.imageUrl) setImgUrl(business.imageUrl);
  }, [business]);

  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ zIndex: 2 }}>
            <Box pt={2} display="block" align="center">
              <Typography variant="h5" color="primary">
                {isLoading ? <Skeleton width="190px" /> : business.name}
              </Typography>
              {!!business.giveBack && showGiveBack && (
                <Typography variant="subtitle1" color="primary">
                  {isLoading ? (
                    <Skeleton width="250px" />
                  ) : (
                    <>
                      {`${parseFloat((business.giveBack * 100).toFixed(2))}%`}{' '}
                      will go to{' '}
                      {isEmpty(organization)
                        ? t('yourChosenNonprofit')
                        : organization.name}
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <CustomImgCard
                  isLoading={isLoading}
                  name={name}
                  width="270px"
                  imageUrl={imgUrl}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CardInfoSection.defaultProps = {
  business: {},
  organization: {},
  showGiveBack: true,
};

CardInfoSection.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
    giveBack: PropTypes.number,
    imageUrl: PropTypes.string,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }),
  organization: PropTypes.shape({
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  showGiveBack: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
  isLoading: businesses.selectors.selectIsLoading(state),
  organization: organizations.selectors.selectOrganization(state),
});

export default connect(mapStateToProps)(CardInfoSection);
