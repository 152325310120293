import * as Yup from 'yup';
import i18n from 'i18next';

const LoyaltyRedeemSchema = () => {
  return Yup.object().shape({
    redeemPoint: Yup.string()
      .min(0, i18n.t('required'))
      .required(i18n.t('required')),
  });
};

export default LoyaltyRedeemSchema;
