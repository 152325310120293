import * as Yup from 'yup';

const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;

const websiteRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const OnBoardSchema = () =>
  Yup.object().shape({
    businessName: Yup.string().min(2, 'Too Short!').required('Required'),
    businessType: Yup.string().required('Required'),
    contactFirstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    contactLastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    businessWeb: Yup.string().matches(websiteRegExp, 'Invalid website URL'),
    contactEmail: Yup.string().email('Invalid email').required('Required'),
    contactPhone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Required'),

    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

export default OnBoardSchema;
