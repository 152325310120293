import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { navigate as navigateGatsby } from 'gatsby';
import SearchSection from './SearchSection';
import TypeFilters from './TypeFilters';
import CategoryFilters from './CategoryFilters';
import GiftCards from './GiftCards';
import CardFilters from './CardFilters';
import {
  businesses,
  organizations,
  types,
  categories,
  checkout,
} from '../../../state';
import { findDefaultType } from '../../../utils';
import SEO from '../../seo';

import { Header, Content, BusinessFooter } from '../../layouts';

const CardCatalog = ({
  fetchBusinesses,
  fetchCategories,
  filterByCategory,
  fetchTypes,
  filterByType,
  selectedType,
  setSelectedCategoryFilter,
  setSelectedTypeFilter,
  fetchOrganization,
  location,
  resetFormData,
}) => {
  React.useEffect(() => {
    resetFormData({});
    if (location.search) {
      const { org: orgId } = queryString.parse(location.search);

      fetchOrganization(orgId);
    }
    (async () => {
      const { payload } = await fetchTypes();
      const defaultType = findDefaultType(payload);
      await fetchCategories(defaultType);

      return defaultType;
    })().then((defaultType) => {
      setSelectedTypeFilter(defaultType);
      fetchBusinesses(defaultType);
    });
  }, []);

  const handleTypeClick = (typeId) => {
    // const org = location.search;
    // if (typeId === 'f888bb54-5e70-4767-98f0-1a3b4a04d7b2') {
    //   navigate(`${!org ? '/comingsoon' : `/comingsoon${org}`}`);
    // } else {
    setSelectedCategoryFilter('');
    fetchCategories(typeId);
    setSelectedTypeFilter(typeId);
    filterByType(typeId);
    // }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryFilter(categoryId);
    filterByCategory({ typeId: selectedType, categoryId });
  };

  const handleCategoryDelete = () => {
    fetchBusinesses(selectedType);
    setSelectedCategoryFilter('');
  };

  const handleCardClick = (businessId) => {
    const org = location.search || '';
    const url = businessId
      ? `card/${businessId}${org && org}`
      : `/comingsoon${org && org}`;

    return navigateGatsby(url);
  };

  return (
    <>
      <SEO title="Gift Card Catalog" />
      <Header logo />
      <Divider />
      <Content style={{ minHeight: 'calc(100vh - 64px - 196px)' }}>
        <SearchSection />
        <Box pb={3}>
          <Box my={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={8}>
                <Box display="flex" flexWrap="nowrap">
                  <TypeFilters handleTypeClick={handleTypeClick} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="block" flexWrap="nowrap" align="right">
                  <CardFilters />
                </Box>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" />
            <Box display="flex" flexWrap="wrap">
              <CategoryFilters
                handleCategoryClick={handleCategoryClick}
                handleDelete={handleCategoryDelete}
              />
            </Box>
          </Box>
          <GiftCards
            handleCardClick={handleCardClick}
            handleTypeClick={handleTypeClick}
          />
        </Box>
      </Content>
      <Divider />
      <BusinessFooter />
    </>
  );
};

CardCatalog.defaultProps = {
  selectedType: '',
  location: {},
};

CardCatalog.propTypes = {
  fetchBusinesses: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  filterByCategory: PropTypes.func.isRequired,
  filterByType: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  setSelectedCategoryFilter: PropTypes.func.isRequired,
  setSelectedTypeFilter: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  resetFormData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedType: types.selectors.selectSelectedType(state),
});

const mapDispatchToProps = (dispatch) => ({
  // fetch
  fetchBusinesses: (typeId) =>
    dispatch(businesses.actions.fetchBusinesses(typeId)),
  fetchCategories: (typeId) =>
    dispatch(categories.actions.fetchCategories(typeId)),
  fetchTypes: () => dispatch(types.actions.fetchTypes()),

  // setters
  setSelectedCategoryFilter: (categoryId) =>
    dispatch(categories.actions.setSelectedCategoryFilter(categoryId)),
  setSelectedTypeFilter: (typeId) =>
    dispatch(types.actions.setSelectedTypeFilter(typeId)),
  resetFormData: () => dispatch(checkout.actions.resetFormData()),

  // filters
  filterByType: (typeId) => dispatch(businesses.actions.filterByType(typeId)),
  filterByCategory: (payload) =>
    dispatch(businesses.actions.filterByCategory(payload)),
  fetchOrganization: (query) =>
    dispatch(organizations.actions.fetchOrganization(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardCatalog);
