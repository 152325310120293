export default [
  'Automotive',
  'Business Support & Supplies',
  'Computers & Electronics',
  'Construction & Contractors',
  'Education',
  'Entertainment',
  'Food & Dining',
  'Health & Medicine',
  'Home & Garden',
  'Legal & Financial',
  'Manufacturing, Wholesale, Distribution',
  'Merchants (Retail)',
  'Personal Care & Services',
  'Real Estate',
  'Travel & Transportation',
  'Miscellaneous',
];
