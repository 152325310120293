import { CardMedia } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  cardMedia: {},
}));

const SponsorImg = (props) => {
  const { alt = '', imageUrl } = props;
  const classes = useStyles();
  return (
    <CardMedia
      className={classes.cardMedia}
      alt={alt}
      image={imageUrl}
      component="img"
      style={{
        height: 30,
        objectFit: 'contain',
      }}
    />
  );
};

SponsorImg.propTypes = {
  alt: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default SponsorImg;
