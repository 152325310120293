import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { businesses, memberships, organizations } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter, Content } from '../../layouts';
import CheckInForm from './CheckInForm';

const CheckIn = ({
  fetchBusiness,
  fetchOrganization,
  business,
  location,
  fetchOrganizations,
  ...nav
}) => {
  const { i18n } = useTranslation();
  const { id: routeId } = nav;
  const dispatch = useDispatch();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: membershipConfig.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(memberships.actions.getMembershipConfig({ id: routeId }));
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { businessName, logoUrl } = membershipConfig;
  return (
    <Box className={classes.textColor}>
      <SEO title={`Member Check-In | ${businessName}`} />
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl=""
        merchantPintunaUrl=""
        bgcolor={membershipConfig.brandColors?.headerColor || '#eee'}
      />
      <Box
        bgcolor={membershipConfig.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
      >
        <Container maxWidth="lg">
          <Content md={7} sm={10}>
            <CheckInForm />
          </Content>
        </Container>
      </Box>
      <Divider />
      <BusinessFooter bgcolor={membershipConfig.brandColors?.footerColor} />
    </Box>
  );
};

CheckIn.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    culture: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
