import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { checkout, organizations } from '../../../state';

const AppleGooglePay = ({
  formData,
  clientSecret,
  handleChange,
  setSucceeded,
  organization,
}) => {
  const { totalAmount } = formData;
  const stripe = useStripe();
  const { t } = useTranslation();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const total = totalAmount * 100;
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (!paymentRequest) {
    return null;
  }

  paymentRequest.on('paymentmethod', async (ev) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {
      paymentIntent,
      error: confirmError,
    } = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      handleChange(t('somethingWentWrong'));
      ev.complete('fail');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          handleChange(t('pleaseTryDifferentPaymentMethod'));
        } else {
          // The payment has succeeded.
          setSucceeded(true);
          if (!isEmpty(organization)) {
            return navigate(`/card/confirmation?org=${organization.id}`);
          }
          return navigate(`/card/confirmation`);
        }
      } else {
        // The payment has succeeded.
        setSucceeded(true);
        if (!isEmpty(organization)) {
          return navigate(`/card/confirmation?org=${organization.id}`);
        }
        return navigate(`/card/confirmation`);
      }
    }
    return null;
  });
  return (
    <Box py={2} align="center">
      <PaymentRequestButtonElement options={{ paymentRequest }} />{' '}
    </Box>
  );
};

AppleGooglePay.propTypes = {
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
  }).isRequired,
  clientSecret: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setSucceeded: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

export default connect(mapStateToProps)(AppleGooglePay);
