import React from 'react';
import PropTypes from 'prop-types';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';

const MembershipFeeDetails = ({ membershipConfig, formData }) => {
  const { t } = useTranslation();

  React.useEffect(() => {}, []);
  const [open, setOpen] = React.useState(false);

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  const FeeDetails = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: membershipConfig.brandColors?.formColor || '#FFF',
      color: membershipConfig.brandColors?.textColor || '#000',
      maxWidth: 500,
      border: '1px solid #000',
    },
  });

  return (
    <FeeDetails
      disableHoverListener
      onMouseEnter={() => show()}
      onClick={() => show()}
      onMouseLeave={() => hide()}
      open={!!open}
      title={
        <Box p={1} style={{ width: 290 }}>
          <Grid container>
            <Grid item xs={7}>
              <Typography>{t('membership.membershipFee')}</Typography>
              {membershipConfig.useTax && membershipConfig.tax > 0 && (
                <Typography>{t('membership.tax')}</Typography>
              )}
              {formData.recurringCost > 0 && (
                <>
                  <Typography>{t('membership.serviceFee')}</Typography>
                  <br />
                </>
              )}
              {formData.oneTimeCost > 0 && (
                <Typography>{t('membership.oneTimeSetupFee')}</Typography>
              )}
              <br />
              <Typography style={{ fontWeight: 'bold' }}>
                {t('membership.totalAmount')}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right' }}>
              <Typography>
                {t('cardValue', {
                  amount: formData.amount,
                  formatParams: {
                    amount: { currency: formData.currency },
                  },
                })}
              </Typography>
              {membershipConfig.useTax && membershipConfig.tax > 0 && (
                <Typography>
                  {t('cardValue', {
                    amount: (formData.amount * membershipConfig.tax) / 100,
                    formatParams: {
                      amount: { currency: formData.currency },
                    },
                  })}
                </Typography>
              )}
              {formData.recurringCost > 0 && (
                <>
                  <Typography>
                    {t('cardValue', {
                      amount: formData.recurringCost,
                      formatParams: {
                        amount: { currency: formData.currency },
                      },
                    })}
                  </Typography>
                  <br />
                </>
              )}
              {formData.oneTimeCost > 0 && (
                <Typography>
                  {t('cardValue', {
                    amount: formData.oneTimeCost,
                    formatParams: {
                      amount: { currency: formData.currency },
                    },
                  })}
                </Typography>
              )}
              <br />
              <Typography style={{ fontWeight: 'bold' }}>
                {t('cardValue', {
                  amount: formData.customerPaysAmount,
                  formatParams: {
                    amount: { currency: formData.currency },
                  },
                })}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      placement="right"
    >
      <Typography
        style={{
          color: 'blue',
          minWidth: 51,
          margin: 'auto',
          textDecoration: 'underline',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        Pricing Details
      </Typography>
    </FeeDetails>
  );
};

MembershipFeeDetails.propTypes = {
  membershipConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MembershipFeeDetails;
