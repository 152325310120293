import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core';

import { businesses, checkCardBalance, tickets } from '../../../state';
import TicketImgCard from './TicketImgCard';

const OrderDetails = () => {
  const { t } = useTranslation();

  const business = useSelector(businesses.selectors.selectBusiness);
  const formData = useSelector(tickets.selectors.selectFormData);
  const cardDetails = useSelector(checkCardBalance.selectors.selectCardDetails);
  // const ticketConfig = useSelector(tickets.selectors.selectTicketEvent);

  if (!business && formData && !cardDetails) {
    return <CircularProgress />;
  }

  const { firstName, lastName, email } = formData;

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card raised>
          <Box display="block">
            <TicketImgCard />
          </Box>
          <CardContent>
            <Box pt={1}>
              <Typography variant="caption" color="textSecondary" component="p">
                {`${t(
                  'ticket.purchaseNote'
                )} ${firstName} ${lastName} at ${email}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
